import { ThemeOptions, alpha } from '@mui/material'
import '@fontsource/open-sans'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  shape: {
    borderRadius: 5,
  },
  spacing: 10,
  palette: {
    primary: {
      light: '#0082E4',
      main: '#006CB0',
      dark: '#006CB0',
      contrastText: '#fff',
    },
    success: {
      main: '#5eb95e',
    },
    secondary: {
      light: '#fff',
      main: '#9DD5FF',
      dark: '#9DD5FF',
      contrastText: '#006CB0',
    },
    error: {
      light: '#fff',
      main: '#DA4760',
      dark: '#DA4760',
      contrastText: '#DA4760',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#3F3F3F',
      secondary: '#0075CE',
      disabled: '#8F8F8F',
    },
    info: {
      light: '#F2F2F2',
      main: '#dce2e8',
      dark: '#C2C2C2',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '36px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '30px',
          borderRadius: 5,
          padding: '5px 10px',
          fontWeight: 400,
          '&.button': {
            border: '1px solid',
          },
          '&.primaryButton': {
            color: '#fff',
            borderColor: '#0082E4',
            backgroundColor: '#0082E4',
            '&:hover': {
              borderColor: '#006CB0',
              backgroundColor: '#006CB0',
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
          '&.secondaryButton': {
            color: '#006CB0',
            borderColor: '#006CB0',
            backgroundColor: '#fff',
            '&:hover': {
              color: '#006CB0',
              borderColor: '#006CB0',
              backgroundColor: '#9DD5FF',
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
          '&.tertiaryButton': {
            color: '#DA4760',
            borderColor: '#DA4760',
            backgroundColor: '#fff',
            '&:hover': {
              color: '#DA4760',
              borderColor: '#DA4760',
              backgroundColor: alpha('#DA4760', 0.2),
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 0,
        },
      },
    },
  },

  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeightBold: 600,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    fontWeightLight: 400,
    fontSize: 13,
    h1: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.8125rem',
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: '0.8125rem',
    },
    overline: {
      fontSize: '0.8125rem',
      textTransform: 'uppercase',
    },
  },
}
