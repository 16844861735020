import { useState, FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Workspace } from '../../../../models'
import { WorkspaceForm } from './workspace-form'
import { LinkedUsers, LinkUsersMenu } from '.'
import { ExistingItem } from './existing-workspace-item'

type ExistingWorkspaceProps = {
  workspace: Workspace
  handleShareWithLicensedUsersChange: (value: boolean) => void
  isSelected?: boolean
}

export const ExistingWorkspace: FC<ExistingWorkspaceProps> = (
  props: ExistingWorkspaceProps
) => {
  const { workspace, handleShareWithLicensedUsersChange, isSelected } = props

  const [sharedWithLicense, setSharedWithLicense] = useState(
    workspace?.sharedWithLicense
  )

  const [isOpen, setIsOpen] = useState(isSelected)

  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleShareClicked = (value: boolean) => {
    setSharedWithLicense(value)
    handleShareWithLicensedUsersChange(value)
  }

  return (
    <>
      <ExistingItem
        key={`${workspace.id}_${uuidv4()}`}
        name={workspace.name}
        onEdit={() => setEditModalOpen(true)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <>
          <LinkUsersMenu
            workspace={workspace}
            shareWithLicensedUsers={sharedWithLicense}
            onShareWithLicensedUsersChange={handleShareClicked}
          />
          {!sharedWithLicense && <LinkedUsers workspace={workspace} />}
        </>
      </ExistingItem>
   
        <WorkspaceForm
          onClose={() => setEditModalOpen(false)}
          workspace={workspace}
          editMode={true}
          open={editModalOpen}
        />
    </>
  )
}
