import { useEffect, useRef, useState } from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import {
  Icon,
  ListItemButton,
  ListItemIcon,
  SvgIconTypeMap,
  Tooltip,
  Typography,
  Theme,
  Stack,
} from '@mui/material'
import { Icon as MdiIcon } from '@mdi/react'
import { alpha } from '@mui/material/styles'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { highlightText } from './highlight-text'
import { makeStyles, createStyles } from '@mui/styles'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import { SxProps } from '@mui/system'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationLink: {
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
      padding: theme.spacing(0),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      paddingLeft: theme.spacing(2),
      //height: 30, //dense ? 40 : 'inherit',
      borderTop: '1px solid #F2F2F2',
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 1),
      },
    },
    navigationLinkText: {
      textWrap: 'wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 'inherit',
    },
  })
)

export function NavigationLink(props: NavLinkProps) {
  const {
    path,
    icon,
    children: text,
    iconPath,
    searchText,
    itemType,
    description,
  } = props
  const isTabSelected = !!useRouteMatch({ path, exact: true })
  const classes = useStyles()

  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)
  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(
          textRef.current.scrollWidth > textRef.current.clientWidth
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow) // Check on resize as well
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  return (
    <Tooltip title={description}>
      <ListItemButton
        to={path}
        component={NavLink}
        className={classes.navigationLink}
        sx={{
          backgroundColor: isTabSelected
            ? theme => alpha(theme.palette.secondary.main, 1)
            : 'inherit',
          ...(props.sx as {}),
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            marginRight: theme => theme.spacing(1),
            color: isTabSelected
              ? theme => alpha(theme.palette.text.primary, 1)
              : 'inherit',
            ...(props.sx as {}),
          }}
        >
          {iconPath ? (
            <MdiIcon path={iconPath} size={0.8} />
          ) : (
            <Icon component={icon} sx={{ width: theme => theme.spacing(2) }} />
          )}
        </ListItemIcon>

        <Tooltip
          title={text}
          disableHoverListener={!isOverflowed}
          sx={{ paddingLeft: 0 }}
        >
          <Stack direction={'row'} gap={0.5} alignItems={'center'}>
            <Typography
              noWrap
              ref={textRef}
              className={classes.navigationLinkText}
              sx={props.sx}
            >
              {highlightText(text, searchText)}
            </Typography>
            {itemType === 'bookmark' ? (
              <BookmarkIcon sx={{ fontSize: '16px' }} />
            ) : (
              ''
            )}
          </Stack>
        </Tooltip>
      </ListItemButton>
    </Tooltip>
  )
}

type NavLinkProps = {
  path: string
  children: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  iconPath?: string
  dense?: boolean
  searchText?: string
  itemType?: string
  sx?: SxProps<Theme>
  description?: string
}
