import React from "react";
import {
  Box,
  Dialog,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      margin: "16px 0",
    },
    dialogContent: {
      // display: flex won't allow margins to properly collapse,
      // so we use block instead
      display: "block",
      padding: theme.spacing(4),
    },
  })
);

export function TabLayoutModal(props: TabLayoutModalProps) {
  const { isOpen, onClose, children, title } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.dialogContent,
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={{
          position: "sticky",
          top: 0,
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.background.default,
          fontWeight: 600,
          height: "41px !important",
          zIndex: 100,
          ml: 0,
          p: 1,
        }}
      >
        <Box>
          {title && typeof title === "string" && (
            <Box>
              <Typography variant="h3">{title}</Typography>
            </Box>
          )}
          {title && typeof title !== "string" && title}
        </Box>
      </Stack>
      {children}
    </Dialog>
  );
}

export type ChildProps = {
  onSave: () => void;
};

export type TabLayoutModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
  title: string;
};
