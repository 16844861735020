import { useQuery } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'

export const getWorkspaceReportsKey = 'get-workspace-reports'

export function useGetWorkspaceReports(
  profileId: string,
  workspaceId: string,
  workspaceName?: string
) {
  return useQuery(
    [getWorkspaceReportsKey, profileId, workspaceId],
    () =>
      workspacesApi.getWorkspaceReports(profileId, workspaceId, workspaceName),
    {
      enabled: !!profileId && !!workspaceId && !!workspaceName,
    }
  )
}
