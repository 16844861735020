import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'

type ReportTilesSkeletonProps = {
  numReports: number
  title: string
  subtitle?: string
}
export function ReportTilesSkeleton(props: ReportTilesSkeletonProps) {
  const { numReports, title, subtitle } = props

  function ReportTitleCircleSkeleton() {
    return (
      <Skeleton
        variant='circular'
        sx={{
          borderRadius: '50%',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minWidth: theme => theme.spacing(6),
          width: theme => theme.spacing(6.5),
          height: theme => theme.spacing(6.5),
        }}
      />
    )
  }

  return (
    <Stack
      sx={{
        width: '100%',
        mt: 2,
      }}
    >
      {title && title !== '' && (
        <>
          <Stack
            direction='row'
            alignItems='baseline'
            gap={1}
            sx={{ height: '25px' }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
            )}
          </Stack>
          <Divider
            sx={{
              mb: 1,
            }}
          />
        </>
      )}

      <Stack direction='row' gap={1} sx={{ flexWrap: 'wrap', width: '100%' }}>
        {[...Array(numReports)].map((_, index) => (
          <Box
            key={`${title}-${index} - skeleton`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              borderRadius: theme => theme.spacing(0.5),
              border: '2px solid',
              borderColor: theme => theme.palette.secondary.main,
              padding: theme => theme.spacing(0.5),
              '&:hover $reportTileButtons': {
                opacity: 1,
                visibility: 'visible !important',
              },
              maxWidth: theme => theme.spacing(46.5),
              minWidth: theme => theme.spacing(40),
              width: '100%',
              minHeight: theme => theme.spacing(8),
              alignItems: 'flex-start',
              height: '100%',
              paddingRight: theme => theme.spacing(1),
            }}
          >
            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              gap={1}
            >
              <ReportTitleCircleSkeleton />
              <Stack direction='column' justifyContent='center'>
                <Skeleton
                  variant='text'
                  sx={{
                    width: '120px',
                    height: '25px',
                  }}
                />
                <Skeleton
                  variant='text'
                  sx={{ flexGrow: 1, width: '160px', height: '20px' }}
                />
              </Stack>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}
