import EqualizerIcon from "@mui/icons-material/Equalizer";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ToggleButton from "@mui/material/ToggleButton";
import { Stack, styled, Typography } from "@mui/material";
import { REPORT_TYPES } from "../../../../../models";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import ListIcon from "@mui/icons-material/List";

type ToggleTypeButtonProps = {
  type: "report" | "paginated-report" | "dataset" | "all";
  setType: (type: "report" | "paginated-report" | "dataset" | "all") => void;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    height: "30px",    
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      height: "30px",
      borderLeft: "1px solid transparent",
    },
}));

export default function ToggleTypeButtons(props: ToggleTypeButtonProps) {
  const { type, setType } = props;

  const buttonStyles = {
    height: "40px",
  };

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      sx={{
        my: 1,
        width: "100%",
        height: "30px",
      }}
    >
      <StyledToggleButtonGroup
        value={type}
        exclusive
        onChange={(_event, newType) => {
          if (newType !== null) {
            setType(newType);
          }
        }}
        sx={{
          height: "40px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          border: 0,
          my: 1,
          backgroundColor: "#efedee",
        }}
        aria-label="type-selector"
      >
        <ToggleButton
          value="all"
          aria-label="all"
          sx={{
            ...buttonStyles,
            color: (theme) =>
              type === "all" && `${theme.palette.primary.main} !important`,
          }}
        >
          <ListIcon />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            All
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="report"
          aria-label="report"
          sx={{
            ...buttonStyles,
            color: (theme) =>
              type === "report" && `${theme.palette.primary.main} !important`,
          }}
        >
          <EqualizerIcon />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            Report
          </Typography>
        </ToggleButton>
        <ToggleButton
          value={REPORT_TYPES.PAGINATED_REPORT}
          aria-label={REPORT_TYPES.PAGINATED_REPORT}
          sx={{
            ...buttonStyles,
            color: (theme) =>
              type === REPORT_TYPES.PAGINATED_REPORT &&
              `${theme.palette.primary.main} !important`,
          }}
        >
          <DescriptionOutlinedIcon />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            Paginated
          </Typography>
        </ToggleButton>
        <ToggleButton
          value={REPORT_TYPES.DATASET}
          aria-label={REPORT_TYPES.DATASET}
          sx={{
            ...buttonStyles,
            color: (theme) =>
              type === REPORT_TYPES.DATASET &&
              `${theme.palette.primary.main} !important`,
          }}
        >
          <GridViewOutlinedIcon />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            Dataset
          </Typography>
        </ToggleButton>
      </StyledToggleButtonGroup>
      {/* <Box
        sx={{
          ...buttonStyles,
          backgroundColor: '#efedee',
          color: allTypes
            ? theme => theme.palette.primary.main
            : theme => theme.palette.grey[600],
          height: '48px',
          alignItems: 'center',
          display: 'flex',
          px: 0.5,
        }}
      >
        <Button
          sx={{
            ...buttonStyles,
            backgroundColor: allTypes ? '#dedede' : 'transparent',
            color: allTypes
              ? theme => theme.palette.primary.main
              : theme => theme.palette.grey[600],

            height: '40px',
            '&:hover': {
              backgroundColor: '#dedede',
            },
          }}
          onClick={() => {
            setAllTypes(!allTypes)
            setType(null)
          }}
          startIcon={<ListIcon />}
        >
          All
        </Button>
      </Box> */}
    </Stack>
  );
}
