import { FC, useRef, useState } from 'react'
import cs from 'classnames'

import {
  Box,
  Button,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Typography,
  Theme,
  Stack,
} from '@mui/material'
import { SxProps } from '@mui/system'
import { useCurrentUserInfo } from '../../hooks/queries/useGetCurrentUserInfo'
import { useRecoilState } from 'recoil'
import { licenseAtom } from '../../state'
import StarBorderIcon from '@mui/icons-material/StarBorder'
// import { useUpdateDefaultLicense } from '../../hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { createStyles, makeStyles } from '@mui/styles'

type CompanyMenuProps = {
  fullWidth?: boolean
  sx?: SxProps<Theme>
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttonShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          width: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
          width: '40px',
        },
      },
      menuButton: {
        marginRight: theme.spacing(0),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
    }),
  { name: 'main-app-bar' }
)

const CompanyMenu: FC<CompanyMenuProps> = (props: CompanyMenuProps) => {
  const { fullWidth } = props
  const { data: userInfo } = useCurrentUserInfo()
  const [currentLicenseId, setCurrentLicense] = useRecoilState(licenseAtom)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClick = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleCloseMenu = (event: MouseEvent | TouchEvent): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onLicenseChange = (licenseId: string): void => {
    setCurrentLicense(licenseId)
    window.location.href = '/'
    setOpen(false)
  }

  // const onFavorite = (event: React.MouseEvent, licenseId: string): void => {
  //   event.stopPropagation()
  //   event.preventDefault()
  //   updateDefaultLicense(licenseId)
  // }

  if (!userInfo || !userInfo.licenses || userInfo.licenses.length <= 1)
    return <></>

  return (
    <>
      <Button
        ref={anchorRef}
        id='company-menu-button'
        aria-controls={open ? 'company-license-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={cs(classes.menuButton, {
          [classes.buttonShrink]: fullWidth,
        })}
        color='inherit'
      >
        <Stack direction='row' spacing={1}>
          <BusinessOutlinedIcon />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Stack>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role='menu'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            {userInfo.licenses && (
              <Paper>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList
                    autoFocusItem={open}
                    id='company-license-menu'
                    onKeyDown={handleListKeyDown}
                  >
                    {userInfo.licenses.map((license, index: number) => [
                      <MenuItem
                        key={license.id}
                        onClick={() => onLicenseChange(license.id)}
                        //disabled={license.id === currentLicenseId}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          width='100%'
                          spacing={1}
                        >
                          <Typography
                            sx={{
                              fontWeight:
                                license.id === currentLicenseId ? 700 : 400,
                            }}
                          >
                            {license.company}
                          </Typography>
                          {license.default && <StarBorderIcon />}
                        </Stack>
                      </MenuItem>,
                      <Box>
                        {index < userInfo.licenses.length - 1 && <Divider />}
                      </Box>,
                    ])}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            )}
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default CompanyMenu
