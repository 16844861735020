import { useEffect, useState } from "react";
import { ExistingItemSkeleton } from "../../shared/existing-items/existing-item-skeleton";
import { TabLayout} from "../../shared";
import { ReportGroupForm } from "./report-group-form";
import { useGetReportGroups } from "../../../../hooks/queries/useGetReportGroups";
import { ReportGroup } from "../../../../models";
import { ExistingReportGroup } from "./existing-report-group";
import { v4 as uuidv4 } from "uuid";

export const ReportGroupsConfig = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TabLayout
        tabTitle="Analytic Types"
        primaryButton={{
          buttonText: "Create Report Group",
          onClick: () => setIsModalOpen(true),
        }}
      >
        <ReportGroupsDisplay />
      </TabLayout>

      <ReportGroupForm
        onClose={() => setIsModalOpen(false)}
        editMode={false}
        open={isModalOpen}
        title="Create Report Group"
      />
    </>
  );
};

export const ReportGroupsDisplay = () => {
  const { data: reportGroups, isLoading: reportGroupsLoading } =
    useGetReportGroups();

  const [skeletonNumber, setSkeletonNumber] = useState(10);

  useEffect(() => {
    const reportGroupsInLocalStorage = localStorage.getItem("reportGroups");
    if (reportGroupsInLocalStorage) {
      setSkeletonNumber(parseInt(reportGroupsInLocalStorage));
    }
  }, []);

  useEffect(() => {
    if (reportGroupsLoading || !reportGroups) return;
    localStorage.setItem("reportGroups", JSON.stringify(reportGroups.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportGroupsLoading]);

  if (reportGroupsLoading) {
    return <ExistingItemSkeleton numberOfItems={skeletonNumber} />;
  }

  return (
    <div>
      {reportGroups?.map((reportGroup: ReportGroup) => (
        <ExistingReportGroup
          key={`${reportGroup.id}_${uuidv4()}`}
          reportGroup={reportGroup}
        />
      ))}
    </div>
  );
};
