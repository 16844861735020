import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { profileApi } from '../../api-interface'
import { licenseAtom } from '../../state'

export const getProfilesKey = 'get-profiles'

export function useGetProfiles() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getProfilesKey, licenseId],
    () => profileApi.getProfilesByLicenseId(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
