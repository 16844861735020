import { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  useGetLicense,
  useGetClientPrincipal,
  useCurrentUserInfo,
  useGetAppSettings,
} from './hooks'
import { AdminPage, LandingPage, ReportsPage } from './pages'
import { useRecoilState } from 'recoil'
import { licenseAtom } from './state'
import { getLicenseState } from './models'
// import { useUpdateUserName } from './hooks/mutations/useUpdateUserName'

import { useLicenseRouting } from './useLicenseRouting'
import { ReportGroupsPage } from './pages/dashboard/report-groups-page'
import { DashboardPage } from './pages/dashboard/dashboard-page'
import { Layout } from './components'

export const routes = {
  reports: {
    index: '/reports',
    report: ({ id, bookmark }: { id: string; bookmark?: string }) =>
      `/reports/${id}${bookmark ? `/${bookmark}` : ''}`,
  },
  reportGroups: {
    index: '/report-groups',
    group: ({ id }: { id: string }) => `/report-groups/${id}`,
  },
  dashboard: {
    index: '/',
  },
  admin: {
    index: '/admin',
    reports: '/admin/reports',
    reportGroups: '/admin/report-groups',
    workspaces: '/admin/workspaces',
    appReg: '/admin/connection-profiles',
    appearance: '/admin/appearance',
    subscription: '/admin/subscription',
  },
}

export function Routes(props: { isLoadingThemeOptions: boolean }) {
  const { data: clientPrincipal, isFetched: isClientFetched } =
    useGetClientPrincipal()
  const { isFetched: appSettingsFetched } = useGetAppSettings()
  const { data: userInfo, isFetched: userInfoFetched } = useCurrentUserInfo()
  const { data: license, isFetched: isLicenseFetched } = useGetLicense()
  // const { mutate: updateUserName } = useUpdateUserName()
  const [currentLicense, setCurrentLicense] = useRecoilState(licenseAtom)

  useLicenseRouting()

  useEffect(() => {
    if (userInfoFetched && userInfo && userInfo.licenses?.[0]) {
      // Set the Current license if it does not exist or it is stale (i.e. not part of User Info Licenses)
      const hasCurrentLicense = userInfo.licenses.find(
        license => license.id === currentLicense
      )
      if (!currentLicense || !hasCurrentLicense) {
        const defaultLicense =
          userInfo.licenses.find(license => license.default) ||
          userInfo.licenses[0]
        setCurrentLicense(defaultLicense.id)
      }

      // For New Users, update UserFullName based upon claims
      // if (userInfo.newUser) {
      //   const name = clientPrincipal.claims.filter(c => c.typ === 'name')[0]
      //     ?.val
      //   const userName: UserFullName = {
      //     firstName: name.split(' ')[0],
      //     lastName: name.split(' ')[1],
      //   }

      //   updateUserName({ userName })
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoFetched])

  // User is not authenticated
  const userEmail = clientPrincipal?.userDetails
  if (userInfoFetched && !userEmail) {
    return <GoToLandingPage />
  }

  // User has no Licenses
  if (
    (userInfoFetched && !userInfo?.user) ||
    (userInfoFetched && !userInfo.licenses.length)
  ) {
    // return <GoToLandingPage />
  }

  //user info -> license -> theme -> app settings -> anything else
  const currentItemLoading = !userInfoFetched
    ? 'user info'
    : !isLicenseFetched
      ? 'configurations'
      : props.isLoadingThemeOptions
        ? 'theme'
        : !appSettingsFetched
          ? 'app settings'
          : 'user data'

  // Page is Loading
  if (
    !appSettingsFetched ||
    !isLicenseFetched ||
    !isClientFetched ||
    !userInfoFetched
  ) {
    return (
      <Layout
        loading={true}
        loadingMessage={`Fetching ${currentItemLoading}...`}
      />
    )
  }

  // Cancelled + Expired License
  // Owner should be redirected to Pricing
  const licenseState = getLicenseState(license)
  if (
    isLicenseFetched &&
    licenseState.status === 'CANCELLED' &&
    licenseState.expired &&
    license?.isOwner
  ) {
    // return <GoToLandingPage />
  }

  return (
    <Switch>
      <Route path={routes.admin.index}>
        {(license?.isAdmin || license?.isOwner) && <AdminPage />}
        {!license?.isAdmin && !license?.isOwner && (
          <Redirect to={routes.reports.index} />
        )}
      </Route>
      <Route path={routes.reportGroups.index}>
        <ReportGroupsPage />
      </Route>
      <Route path='/reports'>
        <ReportsPage />
      </Route>
      <Route path='/'>
        <DashboardPage />
      </Route>
    </Switch>
  )
}

export function GoToLandingPage() {
  return (
    <Switch>
      <Route path='/'>
        <LandingPage />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}
