import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'

import { licenseAtom } from '../../state'

export function LandingPage() {
  const history = useHistory()

  const setCurrentLicense = useSetRecoilState(licenseAtom)

  useEffect(() => {
    setCurrentLicense(null)

    // force a redirect to keycloak login
    history.push('/.auth/login/keycloak')

    // now we need to reload the page, it doesnt navigate
    window.location.reload()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div></div>
}
