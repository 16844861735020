import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'

import { licenseAtom } from '../../state'
import { getWorkspacesKey } from '../queries'
import { workspacesApi } from '../../api-interface/workspace-api'
import { Workspace } from '../../models'

type CreateWorkspaceContext = {
  previousWorkspaces: Workspace[]
}

export type CreateWorkspacePayload = {
  name: string
  profileId: string
  capacityId: string
  description?: string
}

export function useCreateWorkspace() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateWorkspacePayload) =>
      workspacesApi.createWorkspace(payload, licenseId),
    {
      async onMutate(
        newWorkspace: CreateWorkspacePayload
      ): Promise<CreateWorkspaceContext> {
        await queryClient.cancelQueries([getWorkspacesKey, licenseId])

        let previousWorkspaces: Workspace[] = queryClient.getQueryData([
          getWorkspacesKey,
          licenseId,
        ])

        queryClient.setQueryData(
          [getWorkspacesKey, licenseId],
          (old: Workspace[]) => {
            if (old)
              return [
                ...old,
                {
                  ...newWorkspace,
                  name: newWorkspace.name,
                  id: newWorkspace.name,
                },
              ]

            return old
          }
        )

        return { previousWorkspaces }
      },
      onError(_err, _newWorkspace, context: CreateWorkspaceContext) {
        queryClient.setQueryData(
          [getWorkspacesKey, licenseId],
          context.previousWorkspaces
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getWorkspacesKey, licenseId])
      },
    }
  )
}
