import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'
import { workspacesApi } from '../../api-interface/workspace-api'

export const getCustomReportItemsKey = 'get-custom-report-items'

export type GetCustomReportItemsParams = {
  profileId?: string
  datasetId?: string
  workspaceId?: string
  reportId?: string
}

export function useGetCustomReportItems({
  profileId,
  datasetId,
  workspaceId,
  reportId,
}: GetCustomReportItemsParams) {
  const licenseId = useRecoilValue(licenseAtom)

  return useQuery(
    [getCustomReportItemsKey],
    () =>
      workspacesApi.getCustomReportItems(
        {
          profileId,
          datasetId,
          workspaceId,
          reportId,
        },
        licenseId
      ),
    {
      onError: error => {
        // Handle your error here, e.g., log it, show a notification, etc.
        console.error('Error fetching custom report items:', error)
        // Optionally, you can throw the error to propagate it further
        throw error
      },
    }
  )
}
