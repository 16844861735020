import { useMutation } from 'react-query'
import { useRecoilValue } from 'recoil'
import { profileApi } from '../../api-interface'
// import {Profile} from '../../models'
import { licenseAtom } from '../../state'

type AddProfileToWorkspacePayload = {
  profileId: string
  workspaceId: string
}

export function useAddProfileToWorkspace() {
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation((payload: AddProfileToWorkspacePayload) =>
    profileApi.assignProfileToWorkspace({
      profileId: payload.profileId,
      workspaceId: payload.workspaceId,
      licenseId: licenseId,
    })
  )
}
