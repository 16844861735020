import { Stack, Switch, Typography } from '@mui/material'
import { ReportPreview } from './report-preview'
import { FormField } from '../../../shared'
import { SkeletonFormField } from './skeleton-form-field'
import { PBIEmbeddedReportPage, ReportSettingsUpdate } from './types'
import { ApiReport, Profile } from '../../../../../models'
import { useCallback, useState } from 'react'

export type ConfigureReportSettingsProps = {
  report?: ApiReport
  profile?: Profile

  isEdit: boolean
  updateReportSettings: (settings: ReportSettingsUpdate) => void
  setShow: (value: boolean) => void
}

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(-]/
  return specialChars.test(str)
}

export function ConfigureReportSettings(props: ConfigureReportSettingsProps) {
  const { report, profile, isEdit, updateReportSettings, setShow } = props

  const [currentPages, setCurrentPages] = useState<PBIEmbeddedReportPage[]>([])

  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')

  const updateCurrentPages = useCallback((pages: PBIEmbeddedReportPage[]) => {
    setCurrentPages(pages)
  }, [])

  const PagesFormField = () => {
    if (!currentPages || currentPages.length === 0) {
      return <SkeletonFormField label='Default Page' />
    }

    const selectedPage = currentPages?.find(
      page => page.name === report.defaultPageName
    )
    const displayName = selectedPage?.displayName || ''

    const selectOptions = currentPages
      .filter(page => page.visibility !== 1)
      .map(page => page.displayName)

    //If there is only one page, automatically select it
    if (currentPages.length === 1 && !report.defaultPageName) {
      updateReportSettings({ defaultPageName: currentPages[0].name })
    }

    return (
      <FormField
        label='Default Page'
        id='default-page'
        value={displayName}
        helperText=''
        disabled={!currentPages}
        selectOptions={selectOptions}
        onTextChange={value => {
          if (!currentPages) return // Additional safety check
          const selectedPage = currentPages.find(
            page => page.displayName === value
          )

          if (!selectedPage) return
          updateReportSettings({ defaultPageName: selectedPage.name })
        }}
      />
    )
  }

  const ReportSettingsFormFields = () => {
    return (
      <Stack
        direction='column'
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          pl: 1,
        }}
      >
        <ReportSettingsSwitch
          settingName="Always Show on User's Homepage"
          settingValue={report.showOnHome}
          setSettingValue={setShow}
        />
        {report.type === 'report' && (
          <>
            <ReportSettingsSwitch
              settingName='Show Filter Pane'
              settingValue={report.filterPaneEnabled}
              setSettingValue={value =>
                updateReportSettings({ filterPaneEnabled: value })
              }
            />
            <ReportSettingsSwitch
              settingName='Show Page Navigation'
              settingValue={report.showPageNavigation}
              setSettingValue={value =>
                updateReportSettings({ showPageNavigation: value })
              }
            />
          </>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='flex-start'
      width='540px'
    >
      <FormField
        id='display-name'
        label='Display Name'
        value={report.name}
        helperText=''
        onTextChange={value => {
          if (value.length > 50) {
            setDisplayNameErrorMessage(
              'Display name not allowed to have more than 50 characters'
            )
          } else if (containsSpecialChars(value)) {
            setDisplayNameErrorMessage(
              'Special characters not allowed in display name'
            )
          } else {
            setDisplayNameErrorMessage('')
            updateReportSettings({ name: value })
          }
        }}
        errorMessage={displayNameErrorMessage}
      />
      <FormField
        id='description'
        label='Description'
        value={report.description}
        helperText=''
        onTextChange={value => {
          if (value.length > 200) {
            setDescriptionErrorMessage(
              'Description not allowed to have more than 200 characters'
            )
          } else {
            setDescriptionErrorMessage('')
            updateReportSettings({ description: value })
          }
        }}
        errorMessage={descriptionErrorMessage}
        isTextArea={true}
      />

      {report.type === 'report' && <PagesFormField />}
      <ReportSettingsFormFields />

      {report.type === 'report' && (
        <ReportPreview
          reportSettings={{
            defaultPageName: report.defaultPageName,
            filterPaneEnabled: report.filterPaneEnabled,
            showPageNavigation: report.showPageNavigation,
            description: report.description,
            name: report.name,
            pbiReportId: report.pbiReportId,
            pbiWorkspaceId: report.pbiWorkspaceId,
          }}
          profile={profile}
          existingReportId={isEdit ? report.id : null}
          rlsRoles={report?.rls?.roles}
          username={report?.rls?.username}
          setPBIPages={updateCurrentPages}
        />
      )}
    </Stack>
  )
}

type ReportSettingSwitch = {
  settingName: string
  settingValue: boolean
  setSettingValue: (value: boolean) => void
}
function ReportSettingsSwitch(props: ReportSettingSwitch) {
  const { settingName, settingValue, setSettingValue } = props

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ width: '100%' }}
    >
      <Typography variant='subtitle2'>{settingName}</Typography>
      <Switch
        checked={settingValue}
        onChange={e => {
          setSettingValue(e.target.checked)
        }}
      />
    </Stack>
  )
}
