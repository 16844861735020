import cs from 'classnames'
import { createStyles, makeStyles } from '@mui/styles'
import { KeyboardBackspace } from '@mui/icons-material'
import {
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  Stack,
  Box,
  Typography,
  Button,
} from '@mui/material'
import CompanyMenu from './company-menu'
import { NotificationAlert } from './notification-alert'
import { useGetTheme } from '../../hooks'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { bookmarkApi } from '../../api-interface'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'
import { bookmarkAtom } from '../../state/bookmarkAtom'
import logo from '../../assets/ModMedIconWhite.png'
import { getClientPrincipal } from '../auth/util'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbarShrink: {
        height: '36px !important',
        paddingLeft: `${theme.spacing(2)} !important`,
        paddingRight: `${theme.spacing(2)} !important`,
      },
      shrink: {
        height: '35px',
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1301,
      },
      toolbar: {
        minHeight: '35px',
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      buttonShrink: {
        height: '35px',
        width: '40px',
      },

      menuButton: {
        marginRight: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      bookmarkButton: {
        marginLeft: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      rightSide: {
        display: 'flex',
        alignItems: 'center',
      },
      logo: {
        marginLeft: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
      titleShrink: {
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'main-app-bar' }
)

export function MainAppBar(props: MainAppBarProps) {
  const { toggleOpen, fullWidth, pageWidth, showBackButton } = props
  const classes = useStyles()
  const { data: themeWithLogo } = useGetTheme()

  const history = useHistory() // Import useHistory from react-router-dom
  const location = useLocation() // Get the location object from the useLocation hook
  const params = useParams<any>()
  const licenseId = useRecoilValue(licenseAtom)
  const bookmarkState = useRecoilValue(bookmarkAtom)
  const [reportId, setReportId] = useState('')

  useEffect(() => {
    if (bookmarkState?.selectedBookmark?.id) {
      window.history.replaceState(
        {},
        '',
        `/reports/${reportId}/${bookmarkState.selectedBookmark.id}`
      )
    }
  }, [bookmarkState?.selectedBookmark?.id, reportId])
  useEffect(() => {
    const getAndSetBookmarkState = async () => {
      const bookmarkId = params?.bookmark
      if (!bookmarkId) return

      const reportBookmarks = await bookmarkApi.getBookmarks(
        licenseId,
        reportId
      )

      const allBookmarks = [
        ...reportBookmarks.licenseBookmarks,
        ...reportBookmarks.ownedBookmarks,
        ...reportBookmarks.sharedBookmarks,
      ]

      const selectedBookmark = allBookmarks.find(
        bookmark => bookmark.id === bookmarkId
      )

      if (!selectedBookmark) {
        window.history.replaceState({}, '', `/reports/${reportId}`)
        return
      }
    }
    if (!reportId) return
    getAndSetBookmarkState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, reportId])

  useEffect(() => {
    const reportId = params?.id || ''

    if (!reportId || reportId.length < 12) {
      setReportId('')
      return
    }
    setReportId(reportId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  let BackButton = !toggleOpen && (
    <IconButton
      className={classes.menuButton}
      component='a'
      color='inherit'
      aria-label='menu'
      onClick={() => history.push('/')}
      size='small'
      sx={{
        height: '20px',
        width: '20px',
      }}
    >
      <KeyboardBackspace />
    </IconButton>
  )

  let MenuLogo = (
    <img
      src={logo} // fix why this is not showing up
      alt='logo'
      style={{ height: 'auto', maxWidth: '150px', maxHeight: '30px' }}
    />
  )

  return (
    <AppBar
      elevation={0}
      position='fixed'
      className={cs(classes.appBar, { [classes.shrink]: fullWidth })}
    >
      <Toolbar
        className={cs(classes.toolbar, { [classes.toolbarShrink]: fullWidth })}
        sx={{ maxWidth: fullWidth ? '100%' : `${pageWidth}px` }}
      >
        <Box className={classes.leftSide}>
          {showBackButton && BackButton}
          {themeWithLogo?.logo && MenuLogo}
          <NavBarButtons />
        </Box>

        <div className={classes.rightSide}>
          <UserName />
          <CompanyMenu />
        </div>
      </Toolbar>
      <NotificationAlert />
    </AppBar>
  )
}

function NavBarButtons() {
  const history = useHistory()

  return (
    <Stack sx={{ ml: 2 }} direction='row' gap={0}>
      <Button variant='text' onClick={() => history.push('/')}>
        <Typography sx={{ color: theme => theme.palette.primary.contrastText }}>
          Analytics Hub
        </Typography>
      </Button>
    </Stack>
  )
}

function UserName() {
  const [userName, setUserName] = useState('')

  useEffect(() => {
    getClientPrincipal().then(clientPrincipal => {
      const name =
        clientPrincipal?.claims?.find(claim => claim.typ === 'name')?.val || ''

      setUserName(name)
    })
  }, [])

  return (
    <Stack sx={{ mx: 2 }} direction='row'>
      <Typography
        alignContent='center'
        sx={{
          color: '#fff',
        }}
      >
        {userName}
      </Typography>
    </Stack>
  )
}
export type MainAppBarProps = {
  toggleOpen?: () => void
  title: string
  fullWidth?: boolean
  pageWidth?: number
  showBackButton?: boolean
}
export type NavBarButtonsProps = {}
export type ToolbarButtonsProps = {}
