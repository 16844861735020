import {
  useCreateReportGroup,
  useGetReportGroups,
  useUpdateReportGroup,
} from '../../../../hooks'
import { ReportGroup } from '../../../../models'
import { FormField } from '../../shared'
import { useState } from 'react'
import { ContentLoader } from '../../shared/Loader/ContentLoader'
import {
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ImageSelector } from '../appearance/image-selector'
import CustomDialog from '../../shared/dialog/dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentImage: {
      padding: theme.spacing(2),
      borderRadius: '5px',
      maxWidth: '175px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

export type ReportGroupFromProps = {
  onClose: () => void
  editMode?: boolean
  reportGroup?: ReportGroup
  open: boolean
  title: string
}

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(-]/
  return specialChars.test(str)
}

const illegalCharsRegex = /[/\\?%*:|"<>.']/g

export const ReportGroupForm = (props: ReportGroupFromProps) => {
  const { onClose, editMode, reportGroup, open, title } = props
  const theme = useTheme()
  const classes = useStyles()

  const { mutateAsync: createReportGroup, isLoading: isSaving } =
    useCreateReportGroup()
  const { mutateAsync: updateReportGroup, isLoading: isUpdating } =
    useUpdateReportGroup()
  const { data: currentReportGroups } = useGetReportGroups()

  const reportGroupNames = currentReportGroups?.map(g => g.name.toLowerCase())

  const [formError, setFormError] = useState<string>('')

  const [groupNameErrorMessage, setGroupNameErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')
  const [onPermissionsErrorMessage, setOnPermissionsErrorMessage] = useState('')

  const [reportGroupName, setReportGroupName] = useState<string>(
    reportGroup?.name || ''
  )
  const [reportGroupDescription, setReportGroupDescription] = useState<string>(
    reportGroup?.description || ''
  )
  const [reportGroupPermission, setReportGroupPermission] = useState<string>(
    reportGroup?.permissionTitle || ''
  )
  const [showOnHomePage, setShowOnHomePage] = useState<boolean>(
    reportGroup?.showOnHome ?? true
  )
  const toggleShow = () => {
    setShowOnHomePage(o => !o)
  }
  const [image, setImage] = useState<string>(reportGroup?.imageUrl)

  const onReportGroupNameChange = (name: string) => {
    setReportGroupName(name)

    const illegalChars = name.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the following characters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    if (
      reportGroupNames?.includes(name.trim().toLowerCase()) &&
      name !== reportGroup?.id.toLowerCase()
    ) {
      setFormError('Report Group Name already exists')
      return
    }

    setFormError('')
  }

  const onPermissionChange = (title: string) => {
    setReportGroupPermission(title)

    const illegalChars = title.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the folloing charachters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    setFormError('')
  }

  const onDescriptionChange = (description: string) => {
    setReportGroupDescription(description)

    const illegalChars = description.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the folloing charachters: / \\ ? % * : | " \' < > .'
      )
      return
    }
    setFormError('')
  }

  const handleSave = async (): Promise<void> => {
    if (formError) return
    const trimmedGroupName = reportGroupName.trim()

    if (!trimmedGroupName) {
      setFormError('Report Group name is required')
      return
    }

    editMode
      ? await updateReportGroup({
          id: reportGroup.id,
          name: trimmedGroupName,
          description: reportGroupDescription,
          permissionTitle: reportGroupPermission,
          showOnHome: showOnHomePage,
          imageText: image,
        })
      : createReportGroup({
          name: trimmedGroupName,
          description: reportGroupDescription,
          permissionTitle: reportGroupPermission,
          showOnHome: showOnHomePage,
          imageText: image,
        })

    onClose()
  }

  if (isSaving) {
    return <ContentLoader loading={isSaving} />
  }

  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={onClose}
      primaryButtonProps={{
        onClick: handleSave,
        disabled: !!formError || !reportGroupName,
        children: editMode ? 'Update' : 'Create',
      }}
      secondaryButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      error={formError}
      loading={isUpdating || isSaving}
    >
      <FormField
        label='Group Name'
        onTextChange={value => {
          if (value.length > 50) {
            setGroupNameErrorMessage(
              'Group name not allowed to have more than 50 characters'
            )
          } else if (containsSpecialChars(value)) {
            setGroupNameErrorMessage(
              'Special characters not allowed in group name'
            )
          } else {
            setGroupNameErrorMessage('')
            onReportGroupNameChange(value)
          }
        }}
        errorMessage={groupNameErrorMessage}
        value={reportGroupName}
      />
      <FormField
        label='Description'
        onTextChange={value => {
          if (value.length > 200) {
            setDescriptionErrorMessage(
              'Description not allowed to have more than 200 characters'
            )
          } else {
            setDescriptionErrorMessage('')
            onDescriptionChange(value)
          }
        }}
        errorMessage={descriptionErrorMessage}
        value={reportGroupDescription}
        isTextArea={true}
      />
      <FormField
        label='Permissions Title'
        onTextChange={value => {
          if (value.length > 50) {
            setOnPermissionsErrorMessage(
              'Permission Title not allowed to have more than 50 characters'
            )
          } else if (containsSpecialChars(value)) {
            setOnPermissionsErrorMessage(
              'Special characters not allowed in permission title'
            )
          } else {
            setOnPermissionsErrorMessage('')
            onPermissionChange(value)
          }
        }}
        errorMessage={onPermissionsErrorMessage}
        value={reportGroupPermission}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showOnHomePage}
            onChange={toggleShow}
            name='showOnHome'
            color='primary'
          />
        }
        label={'Show Report Group on Home Page'}
        labelPlacement='start'
      />
      {image && (
        <div className={classes.currentImage}>
          <img
            src={image}
            alt='report group preview'
            style={{
              width: '150px',
              marginBottom: 2,
              borderRadius: '50%',
              border: '2px solid #333',
              objectFit: 'cover',
            }}
          />
        </div>
      )}

      {!image && (
        <Typography variant='body2' sx={{ m: 2 }} fontStyle='italic'>
          No image set
        </Typography>
      )}
      <ImageSelector
        label='Image'
        setImage={setImage}
        image={image}
        primaryColor='#FFFFFF'
        secondaryColor={theme.palette.secondary.main}
        preview={{
          height: '200px',
          width: '200px',
          borderRadius: '50%',
          border: '2px solid #333',
        }}
        aspect={1}
      />
    </CustomDialog>
  )
}
