import { Profile } from '../models'
import { getAxios, endpoints, getUrl } from './util'

export const profileApi = {
  // async createProfile(profile: Profile) {
  //   let axios = await getAxios()
  //   const response = await axios({
  //     method: 'post',
  //     url: endpoints.profiles,
  //     data: profile,
  //   })
  //   return response
  // },

  async createProfileConnection({
    profile,
    appRegistrationNodeId,
    appClientId,
    appRegistrationTenantId,
    licenseId,
  }: {
    profile: Profile
    appRegistrationNodeId: string
    appClientId: string
    appRegistrationTenantId: string
    licenseId: string
  }) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.profiles,
      data: {
        profileName: profile.name,
        profileDescription: profile.description,
        appRegistrationNodeId,
        appClientId,
        appRegistrationTenantId,
        licenseId,
      },
    })

    return response
  },

  async assignProfileToWorkspace({
    profileId,
    workspaceId,
    licenseId,
  }: {
    profileId: string
    workspaceId: string
    licenseId: string
  }) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.workspaceProfiles,
      data: {
        profileId: profileId,
        workspaceId: workspaceId,
        licenseId: licenseId,
      },
    })
  },

  async getProfilesByAppRegNodeId(licenseId: string) {
    let axios = await getAxios()

    const url = getUrl(endpoints.profiles, {
      licenseId: licenseId,
      appRegId: licenseId,
    })
    const response = await axios({
      method: 'get',
      url: url,
    })

    const existingAzureRegistrations: Profile[] =
      response?.data?.payload?.map((profile: any) => {
        return {
          id: profile.id,
          name: profile.name,
        }
      }) || []

    return existingAzureRegistrations
  },

  async getProfilesByLicenseId(licenseId: string) {
    let axios = await getAxios()

    const url = getUrl(endpoints.profiles, { licenseId: licenseId })
    const response = await axios({
      method: 'get',
      url: url,
    })

    return response?.data?.payload
  },

  async updateProfile(
    profile: Profile,
    appRegistrationNodeId: string,
    licenseId: string
  ) {
    let axios = await getAxios()
    const patchData = {
      profileName: profile.name,
      profileId: profile.id,
      profileDescription: profile.description,
      appRegistrationNodeId,
      licenseId: licenseId,
    }
    const updateProfileResponse = await axios({
      method: 'patch',
      url: endpoints.profiles,
      data: patchData,
    })

    return updateProfileResponse
  },

  async deleteProfile(
    profileId: string,
    licenseId: string,
    appRegistrationNodeId: string,
    appClientId: string,
    appRegistrationTenantId: string
  ) {
    let deleteData = {
      profileId: profileId,
      licenseId: licenseId,
      appRegistrationNodeId,
      appClientId,
      appRegistrationTenantId,
    }

    let axios = await getAxios()
    const deleteProfileResponse = await axios({
      method: 'delete',
      url: endpoints.profiles,
      data: deleteData,
    })

    return deleteProfileResponse
  },

  // async getProfilesByAppRegId(appRegId: string) {
  //   let axios = await getAxios()

  //   const url = getUrl(endpoints.profile, {appRegId: appRegId})
  //   const response = await axios({
  //     method: 'get',
  //     url: url,
  //   })

  //   const existingAzureRegistrations: Profile[] = response.data.payload.map(
  //     (profile: Profile) => {
  //       return {
  //         id: profile.id,
  //         name: profile.name,
  //       }
  //     }
  //   )

  //   return existingAzureRegistrations
  // }

  //TODO: Implement these functions

  //   async getLinkedReportsByProfile(profileId: string) {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'get',
  //       url: getUrl(endpoints.profileReports, {
  //         profileId: profileId,
  //       }),
  //     })

  //     let reports: Array<{id: string; name: string}> = response.data.payload
  //     return reports.map(r => r.id)
  //   },

  //   async linkReportsToProfile(
  //     profileId: string,
  //     reportIds: string[],
  //     licenseId: string
  //   ): Promise<void> {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'post',
  //       url: endpoints.profileReports,
  //       data: {
  //         licenseId: licenseId,
  //         profileId: profileId,
  //         reportIds: reportIds,
  //       },
  //     })

  //     const {success, errorMessage} = response.data
  //     if (!success) throw new Error(errorMessage)
  //   },

  //   async unlinkReportFromProfile(
  //     profileId: string,
  //     reportId: string
  //   ): Promise<void> {
  //     let axios = await getAxios()

  //     let response = await axios({
  //       method: 'delete',
  //       url: endpoints.profileReports,
  //       data: {
  //         profileId: profileId,
  //         reportId: reportId,
  //       },
  //     })

  //     const {success, errorMessage} = response.data
  //     if (!success) throw new Error(errorMessage)
  //   },
}
