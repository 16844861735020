import { useQuery } from 'react-query'
import { bookmarkApi } from '../../api-interface'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

export const getBookmarksKey = 'get-bookmarks'

export function useGetBookmarks(reportId: string) {
  const licenseId = useRecoilValue(licenseAtom)

  return useQuery(
    [getBookmarksKey, licenseId, reportId],
    () => bookmarkApi.getBookmarks(licenseId, reportId),
    {
      enabled: !!reportId,
    }
  )
}
