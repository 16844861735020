import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Stack,
  TextField,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  Popover,
  Theme,
  Icon,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { NoResultsText } from '../../../../components'

export function LinkItemsMenu(props: LinkItemsMenuProps) {
  const {
    onConfirm,
    addButtonLabel,
    availableItems,
    placeHolderText,
    loading,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [filteredItems, setFilteredItems] = useState<Entity[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [page, setPage] = useState(0) // To keep track of the current page
  const anchorRef = useRef()

  useEffect(() => {
    // Initially set filtered items
    setFilteredItems(availableItems?.slice(page * 10, (page + 1) * 10) || [])
  }, [availableItems, page])

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault()
      closeMenu()
    }
  }

  const handleCheck = (id: string): void => {
    if (selectedIds.includes(id)) {
      setSelectedIds(ids => ids.filter(selectedId => selectedId !== id))
    } else {
      setSelectedIds(ids => [...ids, id])
    }
  }

  const searchItems = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchTextValue = event.target.value.trim()
    setSearchText(searchTextValue)
    const matchingEntities = availableItems.filter(
      (item: Entity) =>
        item.name
          ?.toLocaleLowerCase()
          .includes(searchTextValue.toLocaleLowerCase()) ||
        item.username
          ?.toLocaleLowerCase()
          .includes(searchTextValue.toLocaleLowerCase())
    )
    setFilteredItems(matchingEntities?.slice(0, 10) || []) // Only show max 10 results
    setPage(0) // Reset to first page when searching
  }

  const openMenu = (): void => {
    setSelectedIds([])
    setSearchText('')
    setIsOpen(true)
    setPage(0) // Reset to first page when opening menu
  }

  const closeMenu = (): void => {
    setIsOpen(false)
    setSelectedIds([])
    setSearchText('')
    setPage(0) // Reset to first page when closing menu
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  const prevPage = () => {
    setPage(page - 1)
  }

  return (
    <>
      <Button
        ref={anchorRef}
        className='button secondaryButton'
        disabled={!(availableItems && availableItems.length)}
        onClick={() => openMenu()}
        endIcon={
          loading ? (
            <Icon>
              <CircularProgress size={20} />
            </Icon>
          ) : null
        }
      >
        {addButtonLabel}
      </Button>
      {availableItems && availableItems.length > 0 && (
        <Popover
          sx={{ maxHeight: 'calc(80vh - 64px)' }}
          open={isOpen}
          anchorEl={anchorRef.current}
        >
          <ClickAwayListener onClickAway={() => closeMenu()}>
            <Stack direction='column'>
              <Box
                sx={{
                  boxShadow: 'none',
                  position: 'sticky',
                  maxWidth: '320px',
                  minWidth: '320px',
                  overflow: 'hidden',
                  top: 0,
                  zIndex: 2,
                  p: 1,
                  pt: 1.5,
                  backgroundColor: (theme: Theme) => theme.palette.common.white,
                }}
              >
                <Stack>
                  <TextField
                    variant='standard'
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => searchItems(event)}
                    placeholder={placeHolderText}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <Icon>
                          <SearchIcon fontSize='medium' />
                        </Icon>
                      ),
                    }}
                  ></TextField>
                </Stack>

                <Divider />
              </Box>
              <MenuList sx={{ pt: 0, pb: 0 }} onKeyDown={handleListKeyDown}>
                {!searchText && (
                  <ListItem
                    dense
                    button
                    onClick={() => {
                      if (selectedIds.length === availableItems.length) {
                        setSelectedIds([])
                      } else {
                        setSelectedIds(availableItems.map(r => r.id))
                      }
                    }}
                  >
                    <ListItemText
                      id='selectAll'
                      primary={
                        selectedIds.length === availableItems.length
                          ? 'Deselect All'
                          : 'Select All'
                      }
                    />
                  </ListItem>
                )}
                {filteredItems.map(item => {
                  const labelId = `checkbox-list-label-${item.id}`
                  return (
                    <ListItem
                      key={item.id}
                      dense
                      button
                      onClick={() => handleCheck(item.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={selectedIds.indexOf(item.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={item.name}
                        secondary={item?.username}
                      />
                    </ListItem>
                  )
                })}

                {!filteredItems.length && (
                  <Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      p={1}
                    >
                      <NoResultsText>No results found</NoResultsText>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    boxShadow: 'none',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    backgroundColor: (theme: Theme) =>
                      theme.palette.common.white,
                  }}
                >
                  <Divider />
                  <ListItem dense>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      width='100%'
                      sx={{ mt: 1 }}
                    >
                      <Button
                        className='button secondaryButton'
                        disabled={selectedIds.length === 0}
                        onClick={() => {
                          onConfirm(selectedIds)
                          closeMenu()
                        }}
                      >
                        Link Selected{' '}
                        {selectedIds.length > 0 && (
                          <Typography
                            variant='subtitle2'
                            sx={{
                              ml: 1,
                            }}
                          >
                            ({selectedIds.length})
                          </Typography>
                        )}
                      </Button>
                      <Box>
                        <IconButton disabled={page === 0} onClick={prevPage}>
                          <ArrowBackIosNewIcon />
                        </IconButton>
                        <IconButton
                          disabled={page * 10 + 10 >= availableItems.length}
                          onClick={nextPage}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </ListItem>
                </Box>
              </MenuList>
            </Stack>
          </ClickAwayListener>
        </Popover>
      )}
    </>
  )
}

export type LinkItemsMenuProps = {
  onConfirm: (selectedIds: string[]) => void
  addButtonLabel: string
  availableItems: Entity[]
  placeHolderText?: string
  loading?: boolean
}

type Entity = {
  id?: string
  name?: string
  username?: string
}
