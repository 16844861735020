import { StarBorder, FolderOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Folder } from '../../../models'
import { FolderNav } from './folder-nav'

export function FolderSection(props: FolderSectionProps) {
  const { folders, searchText } = props

  //Sort folders to put isFavorites first
  folders.sort((a, b) => {
    if (a.isFavorites && !b.isFavorites) return -1
    if (!a.isFavorites && b.isFavorites) return 1
    return 0
  })

  return (
    <Box
      sx={{
        // backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
        // borderRadius: '24px',
        py: 0,
      }}
    >
      {folders
        ?.filter(folder => folder.items.length > 0)
        ?.map((folder, index) => (
          <FolderNav
            title={folder.name}
            items={folder.items}
            key={folder.name + index}
            searchText={searchText}
            icon={folder?.isFavorites ? <StarBorder /> : <FolderOutlined />}
          />
        ))}
    </Box>
  )

  // return (
  //   <Box>
  //     <ListItemButton onClick={handleClick} sx={{ pr: 1, mb: 1.5 }}>
  //       <ListItemIcon
  //         sx={{
  //           marginRight: theme => theme.spacing(1),
  //           minWidth: 0,
  //         }}
  //       >
  //         <FolderOutlined />
  //       </ListItemIcon>
  //       <Stack
  //         direction='row'
  //         justifyContent='space-between'
  //         alignItems='center'
  //         sx={{
  //           width: '100%',
  //           pr: '5px',
  //         }}
  //       >
  //         <ListItemText primary={highlightText('Folders', searchText)} />
  //         <Typography noWrap>{filteredItemsNum}</Typography>
  //       </Stack>
  //       {open ? <ArrowDropDown /> : <ArrowDropUp />}
  //     </ListItemButton>
  //     <Collapse
  //       in={searchText.trim().length > 0 ? !open : open}
  //       timeout='auto'
  //       unmountOnExit
  //     >
  //       <Box
  //         sx={{
  //           backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
  //           py: 0,
  //         }}
  //       >
  //         {folders.map((folder, index) => (
  //           <FolderNav
  //             title={folder.name}
  //             items={folder.items}
  //             key={folder.name + index}
  //             highlightText={highlightText}
  //             searchText={searchText}
  //           />
  //         ))}
  //       </Box>
  //     </Collapse>
  //   </Box>
  // )
}
type FolderSectionProps = {
  folders: Folder[]
  sectionName: string
  searchText: string
}
