import { FC, useEffect, useState } from "react";
import { useGetWorkspaces, useUpdateWorkspace } from "../../../../hooks";
import { Workspace, WorkspaceInfo } from "../../../../models";
import { TabLayout } from "../../shared";
import { v4 as uuidv4 } from "uuid";
import { ExistingItemSkeleton } from "../../shared/existing-items/existing-item-skeleton";
import { WorkspaceForm } from "./workspace-form";
import { ExistingWorkspace } from "./existing-workspace";

export const WorkspacesConfig: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TabLayout
        tabTitle="Workspaces"
        primaryButton={{
          buttonText: "Create workspace",
          onClick: () => setIsModalOpen(true),
        }}
      >
        <WorkspacesDisplay />
      </TabLayout>

      <WorkspaceForm
        onClose={() => setIsModalOpen(false)}
        editMode={false}
        open={isModalOpen}
      />
    </>
  );
};

export const WorkspacesDisplay: FC = () => {
  const { data: workspaces, isLoading: workspacesLoading } = useGetWorkspaces();

  const [skeletonNumber, setSkeletonNumber] = useState(10);

  const { mutate: updateWorkspace } = useUpdateWorkspace();

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null
  );

  const handleShareWithLicensedUsersChange = (
    value: boolean,
    workspace: WorkspaceInfo
  ) => {
    setSelectedWorkspaceId(workspace.id);
    updateWorkspace({
      workspaceId: workspace.id,
      workspaceName: workspace.name,
      description: workspace?.description || "",
      sharedWithLicense: value,
    });
  };

  useEffect(() => {
    //Check if we have the number of workspaces in local storage
    const workspacesInLocalStorage = localStorage.getItem("workspaces");
    if (workspacesInLocalStorage) {
      setSkeletonNumber(parseInt(workspacesInLocalStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspacesLoading || !workspaces) return;
    localStorage.setItem("workspaces", JSON.stringify(workspaces.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspacesLoading]);

  if (workspacesLoading) {
    return <ExistingItemSkeleton numberOfItems={skeletonNumber} />;
  }

  return (
    <div>
      {workspaces?.map((workspace: Workspace) => (
        <ExistingWorkspace
          key={`${workspace.id}_${uuidv4()}`}
          workspace={workspace}
          handleShareWithLicensedUsersChange={(value) => {
            handleShareWithLicensedUsersChange(value, workspace);
          }}
          isSelected={selectedWorkspaceId === workspace.id}
        />
      ))}
    </div>
  );
};
