import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  ThemeOptions,
} from '@mui/material'
import { RecoilRoot } from 'recoil'
import { Routes } from './routes'
import { defaultTheme as defaultThemeOptions } from './utils/theme'
import { useGetTheme } from './hooks'
import { GlobalStyles } from './components/common'

export default function App() {
  const [isLoadingThemeOptions, setIsLoadingThemeOptions] = useState(true)

  return (
    <Providers setIsLoadingThemeOptions={setIsLoadingThemeOptions}>
      <CssBaseline />
      <GlobalStyles />
      <Routes isLoadingThemeOptions={isLoadingThemeOptions} />
    </Providers>
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
})

function Providers(props: {
  children: any
  setIsLoadingThemeOptions: (isLoading: boolean) => void
}) {
  return (
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <PbiThemeLoader
            setIsLoadingThemeOptions={props.setIsLoadingThemeOptions}
          >
            <Router>{props.children}</Router>
          </PbiThemeLoader>
        </QueryClientProvider>
      </RecoilRoot>
    </StyledEngineProvider>
  )
}

function PbiThemeLoader(props: {
  children: any
  setIsLoadingThemeOptions: (isLoading: boolean) => void
}) {
  const { data: themeOptions, isLoading: loadingThemeOptions } = useGetTheme()
  const [muiTheme, setMuiTheme] = useState<ThemeOptions>(defaultThemeOptions)

  const { setIsLoadingThemeOptions } = props

  useEffect(() => {
    setIsLoadingThemeOptions(loadingThemeOptions)
  }, [loadingThemeOptions, setIsLoadingThemeOptions])

  useEffect(() => {
    setMuiTheme(themeOptions || defaultThemeOptions)
  }, [themeOptions])

  return (
    <ThemeProvider theme={createTheme({ ...muiTheme })}>
      {props.children}
    </ThemeProvider>
  )
}
