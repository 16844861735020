import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import {
  Typography,
  Button,
  Theme,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      fontWeight: 600,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
    sectionTitle: {
      width: '100%',
      fontWeight: 'bold',
    },
    iconButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      height: '40px',
      marginBottom: theme.spacing(1),
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
      },
    },
  })
)

export function TabLayout(props: TabLayoutProps) {
  const {
    children,
    tabTitle,
    primaryButton,
    secondaryButton,
    editMode,
    iconButtons,
    disableIconButtons,
  } = props

  const classes = useStyles()

  return (
    <Stack
      sx={{ mt: 0 }}
      className={classes.tabRoot}
      direction='column'
      gap={1}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography className={classes.title} variant='h5'>
          {tabTitle}
        </Typography>
        <Stack direction='row' spacing={1}>
          {primaryButton && <TabLayoutButton {...primaryButton} />}
          {secondaryButton && <TabLayoutButton {...secondaryButton} />}
          {editMode && (
            <EditIconButtons
              iconButtons={iconButtons}
              disabled={disableIconButtons}
            />
          )}
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Box>{children}</Box>
    </Stack>
  )
}

type EditIconButtonsProps = {
  iconButtons: TabLayoutIconButtonProps[]
  disabled?: boolean
}

function EditIconButtons(props: EditIconButtonsProps) {
  const classes = useStyles()
  const { iconButtons, disabled } = props
  return (
    <Stack direction='row' className={classes.iconButtons}>
      {iconButtons.map((button, index) => (
        <Tooltip title={button.label} key={button.label}>
          <span>
            <IconButton
              onClick={button.onClick}
              className={classes.iconButton}
              disabled={disabled}
            >
              {button.icon}
            </IconButton>
          </span>
        </Tooltip>
      ))}
    </Stack>
  )
}

function TabLayoutButton(props: TabLayoutButtonProps) {
  const { buttonText, onClick, disabled, variant } = props

  return (
    <Button
      variant={variant || 'contained'}
      disableElevation
      className='button primaryButton'
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  )
}

type TabLayoutButtonProps = {
  buttonText: string
  onClick: () => void
  disabled?: boolean
  variant?: 'contained' | 'outlined'
}

type TabLayoutIconButtonProps = {
  icon: React.ReactElement
  onClick: () => void
  label: string
}

export type TabLayoutProps = {
  children: React.ReactElement
  tabTitle: string
  primaryButton?: TabLayoutButtonProps
  secondaryButton?: TabLayoutButtonProps
  iconButtons?: TabLayoutIconButtonProps[]
  editMode?: boolean
  disableIconButtons?: boolean
}
