import { ChevronLeft } from '@mui/icons-material'
import { Button, Drawer, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      zIndex: 8,
    },
    button: {
      color: theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadius,
      borderWidth: '0.5px',
      borderColor: theme.palette.info.light,
      borderStyle: 'solid',
      fontSize: theme.typography.fontSize,
      marginLeft: theme.spacing(1.5),
      marginTop: theme.spacing(2.5),
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.info.light,
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
      '&:active:after': {
        backgroundColor: 'transparent',
      },
      '&:after': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:selected': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-startIcon': {
        marginRight: 0,
      },
    },
    drawerPaper: {
      zIndex: 8,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  })
)

export function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const { children, toggleOpen } = props
  const classes = useStyles()

  const drawerContents = (
    <div>
      <Button
        className={classes.button}
        sx={{ marginRight: 0 }}
        size='large'
        startIcon={<ChevronLeft />}
        onClick={toggleOpen}
      >
        Hide Menu
      </Button>
      {children}
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      <MobileDrawer {...props}>{drawerContents}</MobileDrawer>
      <DesktopDrawer {...props}>{drawerContents}</DesktopDrawer>
    </nav>
  )
}

export type ResponsiveDrawerProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleOpen: () => void
  anchor?: 'left' | 'right'
}

function MobileDrawer(props: ResponsiveDrawerProps) {
  const { children, isOpen, toggleOpen, anchor } = props
  const classes = useStyles()

  return (
    // The implementation can be swapped with js to avoid SEO duplication of links.

    <Drawer
      variant='temporary'
      anchor={anchor || 'left'}
      open={isOpen}
      onClose={toggleOpen}
      sx={{ display: { lg: 'none', md: 'block' } }}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {children}
    </Drawer>
  )
}

function DesktopDrawer(props: ResponsiveDrawerProps) {
  const { children, isOpen, toggleOpen, anchor } = props
  const classes = useStyles()

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={anchor || 'left'}
      sx={{ display: { lg: 'block', md: 'none' } }}
      variant='temporary'
      open={isOpen}
      onClose={toggleOpen}
    >
      {children}
    </Drawer>
  )
}
