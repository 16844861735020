import { useEffect, useMemo, useState } from 'react'
import { Button, debounce, List, Stack, TextField, Theme } from '@mui/material'
import {
  mdiShieldKeyOutline as appRegistrationIcon,
  mdiChartBoxPlusOutline as reportsIcon,
  mdiPaletteSwatchOutline as palletIcon,
  mdiFolderMultipleOutline as workspacesIcon,
  mdiSelectGroup as reportGroupIcon,
} from '@mdi/js'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import { useGetAppSettings, useGetLicense, useRoutes } from '../../../hooks'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import { NavigationLink } from './navigation-link'
import { FolderSection } from './folder-section'
// import { NavigationSection } from './navigation-section'
import { FolderNav } from './folder-nav'
import { FolderItem, GetNavMenuItemsType } from '../../../models'
import { ReportGroupNav } from './report-group-nav'
import { useGetNavMenuItems } from '../../../hooks/queries/useGetNavMenuItems'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import HomeIcon from '@mui/icons-material/Home'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBar: {
      width: '90%',
      marginLeft: theme.spacing(1.5),
      height: '28px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.info.dark,
      fontSize: theme.typography.fontSize,
      '& input': {
        fontSize: theme.typography.fontSize,
      },
      '& input::placeholder': {
        fontSize: theme.typography.fontSize,
      },
      '& .MuiAutocomplete-inputRoot:before': {
        //boxShadow: '0 0 3px #0082E4 !important',
      },
      '& .MuiInput-root': {
        '&:hover': {
          border: 0,
          boxShadow: '0 0 3px #0082E4 !important',
          '&:before': {
            border: 0,
          },
        },
        '&:focus': {
          boxShadow: '0 0 3px #0082E4',
        },
        '&:active': {
          border: 0,
          boxShadow: '0 0 3px #0082E4',
        },
        '&::selected': {
          border: 0,
          boxShadow: '0 0 3px #0082E4',
        },
        '&:before': {
          border: 0,
        },
        // Border on focus
        '&:after': {
          border: 0,
          boxShadow: '0 0 3px #0082E4',
        },
      },
    },
    searchBarButton: {
      maxWidth: '20px',
      maxHeight: '26px',
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
    },
    navigationLinks: {
      fontSize: theme.typography.fontSize,
      fontWeight: '600',
      color: theme.palette.text.primary,
      padding: 0,
      borderBottom: '1px solid #F2F2F2',
      marginTop: theme.spacing(1),
    },
    navigationLink: {
      fontWeight: '600',
    },
    reportGroups: {
      width: '100%',
      display: 'inline-block',
      font: 'inherit',
    },
  })
)

export function NavigationLinks() {
  const { data: license } = useGetLicense()
  const { data: appSettings } = useGetAppSettings()
  const { data: navMenuItems } = useGetNavMenuItems()

  const routes = useRoutes()
  const [searchText, setSearchText] = useState('')
  const [filteredNavMenuItems, setFilteredNavMenuItems] =
    useState<GetNavMenuItemsType>(navMenuItems)

  const classes = useStyles()

  const filterItems = (items: any[], searchText: string) => {
    const filteredItems = items?.filter(item =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
    return filteredItems
  }

  // Memoization logic inside handleSearch to avoid stale state
  const handleSearch = useMemo(
    () =>
      debounce((text: string) => {
        if (!navMenuItems) return

        const lowerCaseText = text.toLowerCase()

        // Filter top-level folders
        const filteredFolders = navMenuItems.folders
          .map(folder => {
            const folderNameMatches = folder.name
              .toLowerCase()
              .includes(lowerCaseText)
            const filteredItems = folderNameMatches
              ? folder.items
              : filterItems(folder.items, lowerCaseText)

            return {
              ...folder,
              items: filteredItems,
            }
          })
          .filter(
            folder =>
              folder.items.length > 0 ||
              folder.name.toLowerCase().includes(lowerCaseText)
          )

        // Filter reports
        const filteredMyReports = filterItems(
          navMenuItems.reports.myReports,
          lowerCaseText
        )
        const filteredSharedReports = filterItems(
          navMenuItems.reports.sharedReports,
          lowerCaseText
        )

        const filteredBookmarks = filterItems(
          navMenuItems.userBookmarks,
          lowerCaseText
        )

        // Filter report groups and their folders
        const filteredReportGroups = navMenuItems.reportGroups
          ?.map(group => {
            const reportGroupNameMatches = group.name
              ?.toLowerCase()
              .includes(lowerCaseText)

            if (reportGroupNameMatches) return group

            const filteredFoldersInGroup = group.folders
              .map(folder => {
                const folderNameMatches = folder.name
                  .toLowerCase()
                  .includes(lowerCaseText)
                const filteredItems = folderNameMatches
                  ? folder.items
                  : filterItems(folder.items, lowerCaseText)

                return {
                  ...folder,
                  items: filteredItems,
                }
              })
              .filter(
                folder =>
                  folder.items.length > 0 ||
                  folder.name.toLowerCase().includes(lowerCaseText)
              )

            const filteredGroupReports = filterItems(
              group.reports,
              lowerCaseText
            )

            return {
              ...group,
              reports: filteredGroupReports,
              folders: filteredFoldersInGroup,
            }
          })
          ?.filter(
            group =>
              group.reports.length > 0 ||
              group.folders.length > 0 ||
              group.name.toLowerCase().includes(lowerCaseText)
          )

        // Update filtered navigation menu items
        setFilteredNavMenuItems({
          folders: filteredFolders,
          reports: {
            myReports: filteredMyReports,
            sharedReports: filteredSharedReports,
          },
          reportGroups: filteredReportGroups,
          userBookmarks: filteredBookmarks,
        })
      }, 300),
    [navMenuItems]
  )

  // const debouncedHandleSearch = debounce(handleSearch, 300)

  useEffect(() => {
    if (navMenuItems) {
      setFilteredNavMenuItems(navMenuItems)
      setSearchText('')
    }
  }, [navMenuItems])

  const handleClear = () => {
    setSearchText('')
    setFilteredNavMenuItems(navMenuItems)
  }

  const subscriptionNavLinkTitle = `${
    license?.subscriptionStatus ? 'Manage Subscription' : 'License'
  }`

  const memoizedMyReports = useMemo(() => {
    const { myReports } = filteredNavMenuItems?.reports || {}

    if (!myReports || myReports.length === 0) return null
    const ownedReportsFolderItems: FolderItem[] = myReports.map(report => ({
      id: report.id,
      name: report.name,
      description: report.description,
      type: 'report',
    }))

    return (
      <FolderNav
        title='My Reports'
        items={ownedReportsFolderItems}
        icon={<FormatListBulletedOutlinedIcon />}
        searchText={searchText}
      />
    )
  }, [filteredNavMenuItems, searchText])

  const memoizedBookmarks = useMemo(() => {
    const { userBookmarks } = filteredNavMenuItems || {}

    if (!userBookmarks || userBookmarks.length === 0) return null
    const bookmarkFolderItems: FolderItem[] = userBookmarks?.map(bookmark => ({
      id: bookmark.id,
      name: bookmark.name,
      description: bookmark.description,
      type: 'bookmark',
    }))

    return (
      <FolderNav
        title='My Bookmarks'
        items={bookmarkFolderItems}
        icon={<BookmarkBorderOutlinedIcon />}
        searchText={searchText}
      />
    )
  }, [filteredNavMenuItems, searchText])

  const memoizedFolders = useMemo(() => {
    const foldersToRender = filteredNavMenuItems?.folders.filter(
      item => item.name === 'Favorites'
    )
    return foldersToRender?.length > 0 ? (
      <Stack direction='column' gap={1}>
        <FolderSection
          folders={foldersToRender}
          sectionName='Folders'
          searchText={searchText}
        />
      </Stack>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredNavMenuItems])

  const AnalyticSection = () => (
    //<NavigationSection title='Analytics' collapsible>

    <List className={classes.navigationLinks}>
      <NavigationLink path={'/'} icon={HomeIcon} sx={{ fontWeight: 600 }}>
        Analytics Hub
      </NavigationLink>
      {memoizedFolders}
      {memoizedMyReports}
      {memoizedBookmarks}
      {filteredNavMenuItems?.reportGroups?.length > 0 && (
        <Stack direction='column' gap={0}>
          {filteredNavMenuItems?.reportGroups.map(group => (
            <ReportGroupNav
              key={group.id}
              reportGroup={group}
              searchText={searchText}
            />
          ))}
        </Stack>
      )}
    </List>
    //</NavigationSection>
  )

  const AdminSection = () => (
    //<NavigationSection title='Admin' collapsible>
    <List title='Admin'>
      {license?.isPrimaryLicense && (
        <NavigationLink
          sx={{ pt: 0.2, pb: 0.2 }}
          path={routes.admin.appReg}
          iconPath={appRegistrationIcon}
        >
          Connection Profiles
        </NavigationLink>
      )}
      <NavigationLink
        sx={{ pt: 0.2, pb: 0.2 }}
        path={routes.admin.reports}
        iconPath={reportsIcon}
      >
        Reports
      </NavigationLink>
      {license?.isPrimaryLicense && (
        <NavigationLink
          sx={{ pt: 0.2, pb: 0.2 }}
          path={routes.admin.reportGroups}
          iconPath={reportGroupIcon}
        >
          Analytic Types
        </NavigationLink>
      )}
      {license?.isPrimaryLicense && (
        <NavigationLink
          sx={{ pt: 0.2, pb: 0.2 }}
          path={routes.admin.workspaces}
          iconPath={workspacesIcon}
        >
          Workspaces
        </NavigationLink>
      )}
      {license?.isPrimaryLicense && (
        <NavigationLink
          sx={{ pt: 0.2, pb: 0.2 }}
          path={routes.admin.appearance}
          iconPath={palletIcon}
        >
          Appearance
        </NavigationLink>
      )}
      {appSettings?.payPalEnabled && (license?.isOwner || license?.isAdmin) && (
        <NavigationLink
          path={routes.admin.subscription}
          icon={AdminPanelSettingsOutlinedIcon}
        >
          {subscriptionNavLinkTitle}
        </NavigationLink>
      )}
    </List>
    //</NavigationSection>
  )

  return (
    <List
      sx={{
        width: '340px',
      }}
    >
      <TextField
        id='search'
        placeholder=' Search'
        //label='Search Test'
        variant='standard'
        value={searchText}
        onChange={e => {
          const text = escapeRegex(e.target.value)
          setSearchText(text)
          handleSearch(text)
        }}
        className={classes.searchBar}
        InputProps={{
          endAdornment: searchText && (
            <Button onClick={handleClear} className={classes.searchBarButton}>
              <CloseIcon />
            </Button>
          ),
          margin: 'none',
        }}
      />
      <AnalyticSection />
      {license && (license.isAdmin || license.isOwner) && <AdminSection />}
    </List>
  )
}

function escapeRegex(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '')
}
