import { Box, Stack, Typography } from '@mui/material'
import { ApiReport, REPORT_TYPES } from '../../../models'
import CustomDialog from '../../admin/shared/dialog/dialog'
import { DataColumnWithFilters } from '../create-custom-report/custom-report-table'
import { Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import { SortColumn } from '../create-custom-report/generate-rdl-file/types'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableHeaders: {
        fontWeight: 600,
        fontSize: '16px',
      },
    }),
  { name: 'loading-button' }
)

type ReportInfoDialogProps = {
  open: boolean
  onClose: () => void
  report: ApiReport
}

export function ReportInfoDialog(props: ReportInfoDialogProps) {
  const { report, open, onClose } = props

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      paperSx={{
        minWidth: '620px',
        minHeight: '400px',
      }}
      allowBackdropClickClose
      allowEscapeKeyClose
      title={
        <Typography
          variant='h3'
          
        >
          {report.name}
        </Typography>
      }
    >
      <Stack
        direction='column'
        gap={2}
        sx={{
          width: '100%',
        }}
      >
        <Typography>{report?.description}</Typography>
        {report?.type === REPORT_TYPES.CUSTOM_PAGINATED && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              minHeight: 200,
            }}
          >
            {report?.sortVal && <SortVal val={report.sortVal} />}
            <ReportInfoFilters filters={report.filters} />
          </Box>
        )}
      </Stack>
    </CustomDialog>
  )
}

type SortValProps = {
  val: string
}
function SortVal(props: SortValProps) {
  const sortVal: SortColumn = JSON.parse(props.val) as SortColumn

  if (!sortVal) {
    return null
  }

  return (
    <Typography>
      <strong> Sort By: </strong>
      {sortVal?.colId} - {sortVal?.sort?.toUpperCase()}
    </Typography>
  )
}

type ReportInfoFiltersProps = {
  filters: string
}

function ReportInfoFilters(props: ReportInfoFiltersProps) {
  const formattedFilters = JSON.parse(props.filters) as DataColumnWithFilters[]

  const nonNullFilters = formattedFilters?.filter(
    filter => filter?.basicFilter || filter?.advancedFilters?.length > 0
  )
  const classes = useStyles()

  const columns = ['Name', 'Operator', 'Value']

  let allFilters = []
  nonNullFilters?.forEach(filter => {
    if (filter.basicFilter) {
      allFilters.push({
        Name: filter.name,
        Type: 'Basic',
        Operator: '',
        Value: filter.basicFilter.value,
      })
    } else {
      filter.advancedFilters?.forEach(advFilter => {
        allFilters.push({
          Name: filter.name,
          Type: 'Advanced',
          Operator: `${advFilter.operator} ${filter.advancedFilters?.length > 1 ? `(${filter?.filterConnector})` : ''}`,
          Value: advFilter.value,
        })
      })
    }
  })

  const rows = allFilters.map((filter, index) => {
    return {
      id: index,
      ...filter,
    }
  })

  if (nonNullFilters?.length === 0) {
    return <Typography fontStyle='italic'>No Filters Applied</Typography>
  }

  return (
    <Box
      style={{
        width: '100%',
        minHeight: 500,
        height: '100%',
      }}
    >
      <DataGrid
        sx={{
          height: '100%',
          minHeight: 500,
        }}
        rowHeight={28}
        columnHeaderHeight={32}
        // autosizeOnMount={true}
        columns={columns?.map(col => ({
          field: col,
          headerName: col,
          width: col === 'Value' ? 220 : col === 'Type' ? 88 : 160,
          wordWrap: true,
          sortable: false,
          filterable: false,
          headerClassName: classes.tableHeaders,
        }))}
        scrollbarSize={12}
        disableColumnSelector
        slotProps={{
          loadingOverlay: {
            color: 'rgba(0, 0, 0, 0)',
          },
        }}
        rows={rows}
      />
    </Box>
  )
}
