import { useState } from 'react'
import { TabLayoutModal } from '../../shared'
import { ImageEditor } from './image-edit'
import { Button } from '@mui/material'

type ImageSelectorProps = {
  label: string
  setImage: (logo: string) => void
  image: string
  primaryColor: string
  secondaryColor: string
  preview: {
    height: string
    width: string
    borderRadius?: string
    border?: string
  }
  aspect?: number
}

export function ImageSelector(props: ImageSelectorProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { label, image, setImage, primaryColor, preview, aspect } = props

  const handleImageChange = (
    image: HTMLCanvasElement,
    imageRef: HTMLImageElement
  ) => {
    setImage(image.toDataURL('image/png', 1.0))
    setIsModalOpen(false)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          {image && (
            <Button
              style={{ marginTop: '1rem' }}
              className='button tertiaryButton'
              onClick={() => {
                setImage(null)
              }}
            >
              Remove
            </Button>
          )}
          <Button
            style={{ marginTop: '1rem' }}
            className='button secondaryButton'
            onClick={() => setIsModalOpen(true)}
          >
            {`Add ${label}`}
          </Button>
        </div>
      </div>

      <TabLayoutModal
        title={`Add New ${label}`}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ImageEditor
          label={label}
          handleLogoChange={handleImageChange}
          backgroundColor={primaryColor}
          preview={preview}
          aspect={aspect}
        />
      </TabLayoutModal>
    </>
  )
}
