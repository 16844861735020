import { AppRegistration, AppRegistrationState } from '../models'
import { getAxios, endpoints, getUrl } from './util'

export const appRegApi = {
  async createAppRegistration(appReg: AppRegistration) {
    let axios = await getAxios()
    const response = await axios({
      method: 'post',
      url: endpoints.appRegistration,
      data: appReg,
    })
    return response.data.appRegistrationNodeId
  },

  async getAppRegistrations(licenseId: string) {
    let axios = await getAxios()

    const url = getUrl(endpoints.appRegistration, { licenseId: licenseId })
    const response = await axios({
      method: 'get',
      url: url,
    })

    const existingAzureRegistrations: AppRegistrationState[] =
      response.data?.payload?.map((appReg: AppRegistration) => {
        return {
          id: appReg.id,
          name: appReg.name,
          appRegistrationId: appReg.appRegistrationId,
          tenantId: appReg.tenantId,
          profiles: appReg.profiles,
        }
      })

    return existingAzureRegistrations
  },

  async deleteAppRegistration(appRegistrationId: string, licenseId: string) {
    let deleteData = {
      id: appRegistrationId,
      licenseId,
    }
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.appRegistration,
      data: deleteData,
    })
    // TODO: perhaps we need to return the response or at least look at the response.
    return appRegistrationId
  },

  async updateAppRegistration(appReg: AppRegistration) {
    let axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.appRegistration,
      data: appReg,
    })
  },

  async getLinkedReportsByAppReg(appRegId: string) {
    let axios = await getAxios()

    let response = await axios({
      method: 'get',
      url: getUrl(endpoints.appRegistrationReports, {
        appRegId: appRegId,
      }),
    })

    let reports: Array<{ id: string; name: string }> = response.data.payload
    return reports.map(r => r.id)
  },

  async linkReportsToAppReg(
    appRegId: string,
    reportIds: string[],
    profileId: string,
    licenseId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'post',
      url: endpoints.appRegistrationReports,
      data: {
        licenseId: licenseId,
        appRegId: appRegId,
        reportIds: reportIds,
        profileId: profileId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },

  async unlinkReportFromAppReg(
    appRegId: string,
    reportId: string
  ): Promise<void> {
    let axios = await getAxios()

    let response = await axios({
      method: 'delete',
      url: endpoints.appRegistrationReports,
      data: {
        appRegId: appRegId,
        reportId: reportId,
      },
    })

    const { success, errorMessage } = response.data
    if (!success) throw new Error(errorMessage)
  },
}
