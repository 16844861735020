import { License } from '../models'
import { getAxios, endpoints, getUrl } from './util'

export const licenseApi = {
  async getLicense(licenseId: string): Promise<License> {
    const axios = await getAxios()

    const url = getUrl(endpoints.license, { licenseId: licenseId })

    const licenseResponse = await axios({
      method: 'get',
      url,
    })

    return licenseResponse?.data?.payload
  },

  async getFirms(licenseId: string): Promise<License[]> {
    const axios = await getAxios()

    const url = getUrl(endpoints.firms, { licenseId: licenseId })

    const firmsResponse = await axios({
      method: 'get',
      url,
    })

    return firmsResponse?.data?.payload
  },
}
