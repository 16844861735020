import { Button, Stack, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ExistingItem } from '../../shared'
import { AppRegistration } from '../../../../models'
import { useState } from 'react'
import { LinkedProfiles } from './linked-profiles'
import { AddAppRegistrationForm } from './add-app-registration'
import { AddProfileForm } from './add-connection-profile-form'
import { ToastNotificationWithTimeout } from '../../../../components/common/Toast/ToastNotificationWithTimeout'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkedReportsTitle: {
      marginRight: theme.spacing(1),
      alignItems: 'center',
    },
  })
)

export function ExistingAppRegistration(props: ExistingAppRegistrationProps) {
  const { appRegistration, isDisabled, refetch } = props
  const classes = useStyles()
  const [isAppRegModalOpen, setIsAppRegModalOpen] = useState(false)
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [error, setError] = useState<string>('')

  function handleAddNewProfile() {
    setIsProfileModalOpen(true)
  }

  return (
    <>
      <ToastNotificationWithTimeout
        open={!!error}
        message={error}
        variant='error'
        onClose={() => {
          setError('')
        }}
        timeout={3000}
      />
      <ExistingItem
        key={appRegistration.id}
        name={appRegistration.name}
        secondaryLabel={appRegistration.appRegistrationId}
        onEdit={isDisabled ? null : () => setIsAppRegModalOpen(true)}
      >
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography
            variant='body1'
            display='inline'
            className={classes.linkedReportsTitle}
          >
            Linked Profiles
          </Typography>
          <Button
            variant='text'
            className='button secondaryButton'
            onClick={handleAddNewProfile}
          >
            Add New Profile
          </Button>
        </Stack>
        <LinkedProfiles appRegistration={appRegistration} refetch={refetch} />
      </ExistingItem>

      <AddProfileForm
        onClose={() => setIsProfileModalOpen(false)}
        onSave={() => {
          setIsProfileModalOpen(false)
          refetch()
        }}
        open={isProfileModalOpen}
        appReg={appRegistration}
        setError={setError}
      />

      <AddAppRegistrationForm
        open={isAppRegModalOpen}
        onClose={() => setIsAppRegModalOpen(false)}
        onSave={() => setIsAppRegModalOpen(false)}
        isEdit={true}
        appRegistrationToEdit={appRegistration}
        title={'Edit App Registration'}
      />
    </>
  )
}

type ExistingAppRegistrationProps = {
  appRegistration: AppRegistration
  isDisabled?: boolean
  refetch: () => void
}
