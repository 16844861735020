import { useMutation } from 'react-query'
import { reportApi } from '../../api-interface'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

export const testReportConnectionKey = 'test-report-connection'

export type TestReportConnectionPayload = {
  pbiWorkspaceId: string
  itemId: string
  profileId: string
  type: string
}

export function useTestReportConnection() {
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation((payload: TestReportConnectionPayload) =>
    reportApi.testReportConnection(
      payload.pbiWorkspaceId,
      payload.itemId,
      payload.profileId,
      payload.type,
      licenseId
    )
  )
}
