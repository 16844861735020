import {useQuery} from 'react-query'
import {userApi} from '../../api-interface'

export const currentUserInfoKey = 'current-user-info'

export function useCurrentUserInfo() {
  const rootUrl = window.location.origin
  
  return useQuery(currentUserInfoKey, () => userApi.getCurrentUserInfo(rootUrl))
}
