import {
  TextField,
  Theme,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    },
    errorMessage: {
      fontSize: theme.typography.fontSize,
      margin: theme.spacing(0),
      '&.MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.contrastText,
      },
    },
    styledInput: {
      '& .MuiOutlinedInput-root': {
        padding: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.info.dark,
          borderWidth: 1,
          borderRadius: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0 0 3px #0082E4',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0 0 3px #0082E4',
        },
        '&.Mui-active .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0 0 3px #0082E4',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '5px 10px',
      },
    },
    requiredStyle: {
      color: theme.palette.error.dark,
    },
  })
)

/**
 * Can be used as a text field, or a select field by passing
 * the `selectOptions` prop
 */
export function FormField(props: FormFieldProps) {
  const {
    selectOptions,
    label,
    value,
    helperText,
    onTextChange,
    errorMessage,
    allowEmpty,
    required,
    isTextArea,
    onBlur,
    id,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Stack
      direction='column'
      sx={{
        width: '100%',
      }}
    >
      <Stack direction={'row'} gap={0.5}>
        <Typography id={id} className={classes.textField}>
          {label}
        </Typography>
        <Typography
          id={id}
          className={`${classes.textField} ${classes.requiredStyle}`}
        >
          {required ? '*' : ''}
        </Typography>
      </Stack>

      <FormControl
        variant='outlined'
        sx={{ width: '100%', marginBottom: '10px' }}
        fullWidth
        error={!!errorMessage}
      >
        {isTextArea ? (
          <TextField
            multiline
            minRows={4}
            maxRows={4}
            {...rest}
            color={errorMessage ? 'error' : 'secondary'}
            value={value}
            onBlur={onBlur || (() => {})}
            onChange={e => onTextChange(e.target.value)}
            id={id}
            className={`${classes.styledInput}`}
          />
        ) : selectOptions ? (
          <>
            <Select
              {...rest}
              color={errorMessage ? 'error' : 'secondary'}
              variant='outlined'
              fullWidth
              value={value}
              onBlur={onBlur || (() => {})}
              onChange={e => {
                if (allowEmpty && e.target.value === '(None)') {
                  onTextChange('')
                  return
                }
                onTextChange(e.target.value)
              }}
              id={id}
              className={classes.styledInput}
            >
              {renderMenuItems(selectOptions, allowEmpty)}
            </Select>
          </>
        ) : (
          <TextField
            {...rest}
            color={errorMessage ? 'error' : 'secondary'}
            variant='outlined'
            fullWidth
            value={value}
            onBlur={onBlur || (() => {})}
            onChange={e => onTextChange(e.target.value)}
            id={id}
            className={classes.styledInput}
          />
        )}
        <FormHelperText className={classes.errorMessage}>
          {errorMessage}
        </FormHelperText>
      </FormControl>
    </Stack>
  )
}

export type FormFieldProps = {
  id?: string
  selectOptions?: string[] | { [value: string]: string }
  label: string
  value: string
  helperText?: string
  disabled?: boolean
  defaultValue?: string
  type?: string
  errorMessage?: string
  onTextChange?: (val: string) => void
  onBlur?: () => void
  allowEmpty?: boolean
  required?: boolean
  isTextArea?: boolean
}

/**
 * Accepts either an array of strings to use as select options
 * or an object whose keys are the select values, and the key-values
 * are the strings to display in the UI for each option
 */
function renderMenuItems(
  options: FormFieldProps['selectOptions'],
  allowEmpty?: boolean
) {
  if (!options) return null

  let optionsToReturn

  if (Array.isArray(options)) {
    optionsToReturn = options.map(item => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  } else {
    optionsToReturn = Object.entries(options).map(([value, displayText]) => (
      <MenuItem key={value} value={value}>
        {displayText}
      </MenuItem>
    ))
  }

  if (allowEmpty) {
    optionsToReturn.unshift(
      <MenuItem key='(None)' value='(None)'>
        (None)
      </MenuItem>
    )
  }

  return optionsToReturn
}
