import { useEffect, useState } from 'react'
import { FormField, StatusMessage } from '../../shared'
import { useUpdateProfile } from '../../../../hooks'
import { useCreateProfile } from '../../../../hooks/mutations/useCreateProfileConnection'
import { AppRegistration, Profile, StatusMessageInfo } from '../../../../models'
import CustomDialog from '../../shared/dialog/dialog'

export function AddProfileForm(props: AddProfileContentProps) {
  const { onClose, onSave, open, profileToEdit, appReg } = props
  const { mutateAsync: createProfile, isLoading: isSaving } = useCreateProfile()
  const { mutateAsync: updateProfile, isLoading: isUpdating } =
    useUpdateProfile()

  const [description, setDescription] = useState(
    profileToEdit?.description ?? ''
  )
  const [name, setName] = useState(profileToEdit?.name ?? '')
  const [error, setError] = useState('')
  const [status, setStatus] = useState<StatusMessageInfo | null>(null)

  useEffect(() => {
    setDescription(profileToEdit?.description ?? '')
    setName(profileToEdit?.name ?? '')
  }, [profileToEdit])

  function handleNameOrDescriptionChange(value: string, setValue: any) {
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      setValue(value)
      setError('')
    } else {
      setValue(value)
      setError('Profile name cannot contain special characters.')
    }
  }

  async function handleSave() {
    if (error) return

    const profile = {
      profile: {
        name: name,
        description: description,
        id: profileToEdit?.id || undefined,
      },
      appRegistrationNodeId: appReg.id,
      appClientId: appReg.appRegistrationId,
      appRegistrationTenantId: appReg.tenantId,
    }

    try {
      if (!profileToEdit) {
        await createProfile(profile)
      } else {
        await updateProfile(profile)
      }
      onSave()
      onClose()
    } catch (e) {
      setStatus({
        title: 'Error Creating Profile',
        type: 'error',
        message: e.response.data.errorMessage,
      })
    }
  }

  return (
    <CustomDialog
      title='Add Connection Profile'
      open={open}
      loading={isSaving || isUpdating}
      onClose={onClose}
      primaryButtonProps={{
        onClick: () => handleSave(),
        children: 'Save',
      }}
      secondaryButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      // loading={isSaving || isUpdating}
    >
      <FormField
        label='Name'
        value={name}
        errorMessage={error}
        disabled={!!profileToEdit}
        onTextChange={e => handleNameOrDescriptionChange(e, setName)}
      />
      <FormField
        label='Description'
        value={description}
        helperText=''
        onTextChange={setDescription}
        isTextArea={true}
      />
      {status && <StatusMessage status={status} />}
    </CustomDialog>
  )
}

export type AddProfileContentProps = {
  onClose?: () => void
  onSave?: () => void
  isEdit?: boolean
  open: boolean
  profileToEdit?: Profile
  appReg: AppRegistration
  setError: (error: string) => void
}
