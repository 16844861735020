import { ExistingItem } from '../../shared'
import { ApiReport, REPORT_TYPES } from '../../../../models'
import { useDeleteReport } from '../../../../hooks'
import { useState } from 'react'
import { Home } from '@mui/icons-material'
import { AddReportForm } from './add-report-form/add-report-form'
import { FolderItemTypeToIcon } from '../../../reports/report-dashboard/util'

export function ExistingReport(props: ExistingReportProps) {
  const { report, isDisabled } = props

  const { mutate: deleteReport, isLoading: isDeleting } = useDeleteReport()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editState, setEditState] = useState<'connection' | 'details'>(
    'connection'
  )

  let icon
  if (report?.showOnHome) icon = <Home style={{ fontSize: '16px' }} />

  function handleDelete() {
    deleteReport(report.id)
  }

  return (
    <>
      <ExistingItem
        key={report.id + report.name}
        secondaryLabel={report.description}
        name={report.name}
        loading={isDeleting}
        onDelete={isDisabled ? null : handleDelete}
        onEdit={
          isDisabled
            ? undefined
            : () => {
                setEditState('details')
                setIsModalOpen(true)
              }
        }
        editButtonName='Edit Details'
        thirdButtonName='Edit Connection'
        //if report type is not custom_paginated then show third button
        onThirdButton={
          report.type !== REPORT_TYPES.CUSTOM_PAGINATED
            ? () => {
                setEditState('connection')
                setIsModalOpen(true)
              }
            : undefined
        }
        nameIcon={<FolderItemTypeToIcon type={report.type} />}
        icon={icon}
      />

      {isModalOpen && (
        <AddReportForm
          onClose={() => setIsModalOpen(false)}
          onSave={() => setIsModalOpen(false)}
          isEditingConnection={editState === 'connection'}
          isEditingDetails={editState === 'details'}
          reportToEdit={report}
          open={isModalOpen}
        />
      )}
    </>
  )
}

export type ExistingReportProps = {
  report: ApiReport
  isDisabled?: boolean
}

export type RelationshipQuery = {
  sources: string
  relationships: string
  targetTypes: string
  direction: string
}
