import React from 'react'
import { IconButton, InputAdornment, TextField, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Delete as TrashCanIcon } from '@mui/icons-material'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      row: {
        width: '100%',
        display: 'flex',
        marginBottom: theme.spacing(1),
      },
      role: {
        width: '240px',
        flexGrow: 1,
        marginRight: theme.spacing(0.5),
      },
      userRlsProperty: {
        width: '240px',
        flexGrow: 1,
        marginLeft: theme.spacing(0.5),
      },
      iconPlaceholder: {
        width: theme.spacing(6),
      },
    }),
  { name: 'rls-row' }
)

export function RlsRow(props: RlsRowProps) {
  const { onRoleChange, role, index, onDelete } = props

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <TextField
        className={classes.role}
        label='Role Name'
        size='small'
        color='primary'
        variant='filled'
        onChange={e => onRoleChange(e.target.value, index)}
        value={role}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {onDelete ? (
                <IconButton onClick={() => onDelete(index)} size='large'>
                  <TrashCanIcon />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

type RlsRowProps = {
  onRoleChange: (value: string, index: number) => void
  role: string
  index: number
  onDelete?: (index) => void
}
