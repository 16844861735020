import { styled, Box, Button, Dialog, Theme } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root.MuiDialog-paper': {},
  //shift dialog down 100px
  top: '64px',
  //hide the scroll bar
  '&::-webkit-scrollbar': {
    display: 'none !important',
  },
}))

export const StyledDialogContainer = styled(Box)({
  // overflow: 'hidden',
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: '200px',
  justifyContent: 'space-between',
  padding: 0,
  margin: 0,
})

export const StyledDialogFooter = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    position: 'sticky',
    display: 'flex',
    height: 'auto',
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button:not(:last-of-type)': {
      marginRight: theme.spacing(1),
    },
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
  })
)

export const StyledDialogButton = styled(Button)(
  ({ theme }: { theme: Theme }) => ({
    my: theme.spacing(1),
    ml: theme.spacing(1),
  })
)
