import {
  alpha,
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Folder } from '../../../../models'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

type AddToFolderOptionsProps = {
  folders: Folder[]
  handleAddToFolder: (folderId: string, newFolderName?: string) => void
  handleFolderClose: () => void
}

export function AddToFolderOptions(props: AddToFolderOptionsProps) {
  const { folders, handleAddToFolder, handleFolderClose } = props

  const [isAddingFolder, setIsAddingFolder] = useState(false)

  const [newFolderName, setNewFolderName] = useState('')

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ p: 1, maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
        Add to Folder
      </Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Stack
        onClick={e => {
          e.stopPropagation()
          setIsAddingFolder(true)
        }}
        direction={'row'}
        justifyContent={'flex-start'}
        sx={{
          cursor: 'pointer',
          width: '100%',
          p: 1,
          '&:hover': {
            backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
          },
          border: theme =>
            !isAddingFolder
              ? `1px dashed ${theme.palette.primary.main}`
              : 'none',
        }}
      >
        {isAddingFolder ? (
          <>
            <TextField
              autoFocus
              label='Folder Name'
              variant='standard'
              size='small'
              sx={{ width: '100%' }}
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              //if enter key is pressed, add folder
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsAddingFolder(false)
                  handleAddToFolder('', newFolderName)
                  handleFolderClose()
                }
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    onClick={() => {
                      setIsAddingFolder(false)
                      handleAddToFolder('', newFolderName)
                      handleFolderClose()
                    }}
                    sx={{
                      fontSize: '14px',
                      mb: 1,
                      cursor: 'pointer',
                      color: theme => theme.palette.primary.main,
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Confirm
                  </Typography>
                ),
              }}
            />
          </>
        ) : (
          <>
            <Typography
              fontStyle={'italic'}
              sx={{
                cursor: 'pointer',
              }}
            >
              Create New
            </Typography>
            <AddOutlinedIcon />
          </>
        )}
      </Stack>

      {folders
        ?.filter(folder => !folder.disableEdit)
        ?.map((folder, index) => (
          <Box
            key={index}
            onClick={e => {
              e.stopPropagation()
              handleAddToFolder(folder.id)
              handleFolderClose()
            }}
            sx={{
              cursor: 'pointer',
              width: '100%',
              p: 1,
              '&:hover': {
                backgroundColor: theme =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
          >
            <Typography>{folder.name}</Typography>
          </Box>
        ))}
    </Stack>
  )
}
