import { useCallback, useEffect, useState } from 'react'
import {
  ApiReport,
  StatusMessageInfo,
  ReportToCreate,
  WorkspaceDatasetInfo,
  REPORT_TYPES,
  Profile,
} from '../../../../../models'
import {
  useCreateReport,
  useUpdateReport,
  useGetLicense,
  useGetProfiles,
} from '../../../../../hooks'
import { AddReportContentProps, ReportSettingsUpdate } from './types'
import { ConfigureReportSettings } from './configure-report-settings'
import { SelectAndAuthenticateReport } from './select-and-authenticate'
import { workspacesApi } from '../../../../../api-interface'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../../../../state'
import CustomDialog from '../../../shared/dialog/dialog'
import { PermissionSettings } from './item-permission-settings'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { CircularProgress } from '@mui/material'

function reportTypeToItemType(
  reportType: string
): 'dataset' | 'report' | 'paginated-report' {
  switch (reportType) {
    case REPORT_TYPES.REPORT:
      return 'report'
    case REPORT_TYPES.PAGINATED_REPORT:
      return 'paginated-report'
    case REPORT_TYPES.DATASET:
      return 'dataset'
    default:
      return 'report'
  }
}

export function AddReportForm(props: AddReportContentProps) {
  const {
    onClose,
    reportToEdit,
    open,
    onSave,
    isEditingConnection,
    isEditingDetails,
  } = props

  const [activeStep, setActiveStep] = useState(isEditingDetails ? 1 : 0)
  const [dataset, setDataset] = useState<WorkspaceDatasetInfo | null>(null)

  const [perspectives, setPerspectives] = useState<string[]>([])
  const [perspectivesLoading, setPerspectivesLoading] = useState(false)

  const [autoTestConnection, setAutoTestConnection] = useState(true)

  const [itemTypeOption, setItemTypeOption] = useState<
    'dataset' | 'report' | 'paginated-report' | 'all'
  >(reportToEdit?.type ? reportTypeToItemType(reportToEdit?.type) : 'report')

  const [itemType, setItemType] = useState<
    'dataset' | 'report' | 'paginated-report'
  >(reportToEdit?.type ? reportTypeToItemType(reportToEdit?.type) : 'report')

  useEffect(() => {
    setStatus(null)
    if (itemTypeOption === 'all') return
    setItemType(itemTypeOption)
  }, [itemTypeOption])

  const [allTypes, setAllTypes] = useState(false)

  const [report, setReport] = useState<ApiReport>(
    reportToEdit ?? {
      pbiReportId: '',
      pbiWorkspaceId: '',
      name: '',
      pbiReportName: '',
      appRegistrationName: '',
      appRegistrationNodeId: '',
      owner: '',
      description: '',
      defaultPageName: '',
      filterPaneEnabled: false,
      showPageNavigation: false,
      showOnHome: false,
      perspective: '',
    }
  )

  const [profile, setProfile] = useState<Profile | null>(null)
  const [status, setStatus] = useState<StatusMessageInfo>(null)

  const { data: profiles } = useGetProfiles()

  const { data: license } = useGetLicense()
  const { mutateAsync: createReport, isLoading: isSavingReport } =
    useCreateReport()
  const { mutateAsync: updateReport, isLoading: isUpdating } = useUpdateReport()

  const [permissions, setPermissions] = useState<PermissionSettings>({
    canCreate: false,
    canCreateBlank: false,
    canUseTableBuilder: false,
  })

  const licenseId = useRecoilValue(licenseAtom)

  const [isGrantingAccess, setIsGrantingAccess] = useState(false)

  const updateReportSettings = useCallback((settings: ReportSettingsUpdate) => {
    setReport(prevReport => ({ ...prevReport, ...settings }))
  }, [])

  useEffect(() => {
    if (!report?.profileId) return
    setProfile(profiles?.find(p => p.id === report.profileId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles])

  useEffect(() => {
    const getPerspectives = async () => {
      const uniquePerspectives = await getPerspectivesForDataset()
      setPerspectives(uniquePerspectives || [])
    }
    getPerspectives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset])

  useEffect(() => {
    setReport(prevReport => ({
      ...prevReport,
      pbiReportName: '',
    }))
    setDataset(null)
    setPerspectives([])
    setPermissions({
      canCreate: false,
      canCreateBlank: false,
      canUseTableBuilder: false,
    })
    setStatus(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setItemType])

  const getPerspectivesForDataset = async () => {
    if (!profile || !report?.pbiWorkspaceId || !dataset) return

    setPerspectivesLoading(true)
    const perspectives = await workspacesApi.getPerspectives(
      {
        profileId: profile.id,
        datasetId: dataset.id,
        workspaceId: report.pbiWorkspaceId,
      },
      licenseId
    )

    setPerspectivesLoading(false)

    return perspectives
  }

  async function handleSave() {
    if (!report || !profile || !report.name) {
      return
    }

    if (itemType === REPORT_TYPES.DATASET && !dataset && !isEditingDetails)
      return

    if (!report.pbiReportId && itemType !== REPORT_TYPES.DATASET) return

    let reportData: ReportToCreate = {
      id: reportToEdit?.id || null,
      name: report.name,
      pbiReportName:
        itemType === REPORT_TYPES.DATASET ? '' : report.pbiReportName,
      description: report?.description || '',
      pbiReportId: itemType === REPORT_TYPES.DATASET ? '' : report.pbiReportId,
      pbiWorkspaceId: report.pbiWorkspaceId,
      datasetId: itemType === REPORT_TYPES.DATASET ? dataset?.id : '',
      defaultPageName: report.defaultPageName,
      filterPaneEnabled: report.filterPaneEnabled,
      showPageNavigation: report.showPageNavigation,
      profileName: profile?.name,
      profileId: profile.id,
      licenseNodeId: license.id,
      url: report.url,
      rls: report.rls,
      showOnHome: report.showOnHome,
      permissions: JSON.stringify(permissions),
      perspective: report.perspective,
      type: itemType,
    }

    if (!reportToEdit) {
      const reportNodeId = (await createReport(reportData))?.id || ''
      setReport(prevReport => ({ ...prevReport, id: reportNodeId }))
    } else {
      await updateReport(reportData)
    }
  }

  const handleConfirm = async () => {
    if (activeStep === 0 && !isEditingConnection) {
      setActiveStep(1)
      return
    }
    await handleSave()
    onSave()
  }

  const renderConfigureReportSettings = () => {
    return (
      <ConfigureReportSettings
        profile={profile}
        report={{
          ...report,
          type: itemType,
        }}
        isEdit={!!reportToEdit}
        updateReportSettings={updateReportSettings}
        setShow={(value: boolean) => {
          setReport({ ...report, showOnHome: value })
        }}
      />
    )
  }

  const renderSelectAndAuthenticateReport = () => {
    return (
      <SelectAndAuthenticateReport
        report={report}
        setReport={setReport}
        profile={profile}
        setProfile={setProfile}
        status={status}
        setStatus={setStatus}
        isGrantingAccess={isGrantingAccess}
        setIsGrantingAccess={setIsGrantingAccess}
        typeOption={itemTypeOption}
        setTypeOption={setItemTypeOption}
        type={itemType}
        setType={setItemType}
        dataset={dataset}
        setDataset={setDataset}
        isEdit={!!reportToEdit}
        perspectives={perspectives}
        perspectivesLoading={perspectivesLoading}
        permissions={permissions}
        setPermissions={setPermissions}
        allTypes={allTypes}
        setAllTypes={setAllTypes}
        autoTestConnection={autoTestConnection}
      />
    )
  }

  //prettier-ignore
  const confirmButtonText =
    activeStep === 1 || isEditingConnection ? 'Save' : 'Configure'

  const disableSave = () => {
    if (isEditingDetails) {
      return !report.name
    }

    if (status?.type === 'error') {
      return true
    }

    if (!profile || !report.pbiWorkspaceId) return true
    if (itemType === REPORT_TYPES.DATASET && !dataset) return true
    if (!report.pbiReportId && itemType !== REPORT_TYPES.DATASET) return true

    return false
  }

  const isSaving = isSavingReport || isUpdating

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={'Add Item'}
      primaryButtonProps={{
        children: confirmButtonText,
        disabled: disableSave() || isSaving,
        endIcon: isSaving ? (
          <CircularProgress size='1rem' />
        ) : activeStep === 0 && !isEditingConnection ? (
          <ChevronRightIcon />
        ) : (
          ''
        ),
        onClick: handleConfirm,
      }}
      allowBackdropClickClose
      allowEscapeKeyClose
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: onClose,
      }}
      {...(activeStep === 1 &&
        !reportToEdit && {
          tertiaryButtonProps: {
            children: 'Back',
            onClick: () => {
              setAutoTestConnection(false)
              setActiveStep(0)
            },
            startIcon: <ChevronLeftIcon />,
          },
        })}
      paperSx={{
        alignItems: 'center',
        height: '680px',
      }}
      width={580}
    >
      {activeStep === 0 && renderSelectAndAuthenticateReport()}
      {activeStep === 1 && renderConfigureReportSettings()}
    </CustomDialog>
  )
}
