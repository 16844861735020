import { styled } from '@mui/material/styles'
import { Box, Snackbar } from '@mui/material/'

export const StyledToastBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[600],
  padding: theme.spacing(2),
  display: 'flex',
  width: '100%', // Make it full-width
  justifyContent: 'center', // Center content
}))

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  left: 0,
  top: 0,
  right: 0,
}))
