import { Skeleton, Stack, Typography } from "@mui/material";

export function SkeletonFormField({ label }: { label?: string }) {
  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        my: 1,
      }}
    >
      {label && (
        <Typography
          variant="h6"
          sx={{
            textAlign: "left",
          }}
          fontWeight={600}
        >
          {label}
        </Typography>
      )}
      <Skeleton
        variant="rectangular"
        width="100%"
        height="30px"
        animation="wave"
      />
    </Stack>
  );
}
